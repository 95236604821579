import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import camera from "../Images/camera.png";
import Stack from "@mui/material/Stack";
import vr from "../Images/vr.png";
import axios from "axios";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch, Space } from "antd";
import "../App.css";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;

const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const InstituteForm = () => {
  const initialFormData = {
    uploadInstituteLogo : null,
    instituteID: "",
    instituteName: "",
    instituteType: "",
    pointOFContact: "",
    landlineNumber: "",
    mobileNumber: "",
    emailId: "",
    password: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    pinCode: "",
    country: "",
    state: "",
    district: "",
    numberOfBranches: "",
    numberOfStudents: "",
    numberOfFaculties: "",
    cloudSpace: "",
    cloudSpaceType: false,
    vrEnabled: false,
    UniqueId:localStorage.getItem("Unique_id")
  };

  const [formData, setFormData] = useState(initialFormData);

  const [instituteLogo, setInstituteLogo] = useState(null);

  const handleVr = (checked) => {
    setFormData((prev) => ({
      ...prev,
      vrEnabled: checked,
    }));
  };

  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCloudSpaceType = (checked) => {
    setFormData((prev) => ({
      ...prev,
      cloudSpaceType: checked,
    }));
  };

  const handleReset = () => {
    setFormData(initialFormData);
  };

  const [imageUrl, setImageUrl] = useState(null);

  const handleImageUpload = (e) => {
    const uploadInstituteLogo = e.target.files[0];
    // Check if a file was selected
    if (uploadInstituteLogo) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Set the image URL to the reader's result, which is the base64 data URL of the uploaded image
        setImageUrl(e.target.result);
      };

      reader.readAsDataURL(uploadInstituteLogo);
      setInstituteLogo(uploadInstituteLogo);
    }
  };

  console.log(formData.uploadInstituteLogo)

  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();


    const formAppendData = new FormData();
    formAppendData.append("uploadInstituteLogo", instituteLogo);
    formAppendData.append("instituteID", formData.instituteID);
    formAppendData.append("instituteName", formData.instituteName);
    formAppendData.append("instituteType", formData.instituteType);
    formAppendData.append("pointOFContact", formData.pointOFContact);
    formAppendData.append("landlineNumber", formData.landlineNumber);
    formAppendData.append("mobileNumber", formData.mobileNumber);
    formAppendData.append("emailId", formData.emailId.toLowerCase());
    formAppendData.append("password", formData.password);
    formAppendData.append("addressLine1", formData.addressLine1);
    formAppendData.append("addressLine2", formData.addressLine2);
    formAppendData.append("area", formData.area);
    formAppendData.append("pinCode", formData.pinCode);
    formAppendData.append("country", formData.country);
    formAppendData.append("state", formData.state);
    formAppendData.append("district", formData.district);
    formAppendData.append("numberOfBranches", formData.numberOfBranches);
    formAppendData.append("numberOfStudents", formData.numberOfStudents);
    formAppendData.append("numberOfFaculties", formData.numberOfFaculties);
    formAppendData.append("cloudSpace", formData.cloudSpace);
    formAppendData.append(
      "cloudSpaceType",
      formData.cloudSpaceType ? "GB" : "TB"
    );
    formAppendData.append("vrEnabled", formData.vrEnabled);
    formAppendData.append("UniqueId", localStorage.getItem("Unique_id"))


    const formSendData = {
      uploadInstituteLogo : formData.uploadInstituteLogo,
      instituteID: formData.instituteID,
      instituteName: formData.instituteName,
      instituteType: formData.instituteType,
      pointOFContact:formData.pointOFContact,
      landlineNumber: formData.landlineNumbero,
      mobileNumber: formData.mobileNumber,
      emailId: formData.emailId,
      password: formData.password,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      area: formData.area,
      pinCode: formData.pinCode,
      country: formData.country,
      state: formData.state,
      district: formData.district,
      numberOfBranches: formData.numberOfBranches,
      numberOfStudents: formData.numberOfStudents,
      numberOfFaculties: formData.numberOfFaculties,
      cloudSpace: formData.cloudSpace,
      cloudSpaceType: formData.cloudSpaceType ? "GB" : "TB",
      vrEnabled: formData.vrEnabled,
      UniqueId:localStorage.getItem("Unique_id")
    };
  

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if(formData.uploadInstituteLogo === null  && instituteLogo === null ){
      toast.warn("please upload your institute logo");
    }else if (instituteLogo !== null){
      if (editMode) {
        axios
          .put(`${apiUrl}/institute/${id}`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/institutes");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/institute`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/institutes");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }else if(formData.uploadInstituteLogo !== null){
      if (editMode) {
        axios
          .put(`${apiUrl}/institute/${id}`, formSendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/institutes");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/institute`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/institutes");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }

    // if (formData.uploadInstituteLogo === null && instituteLogo === null) {
    //   toast.warn("please upload your institute logo");
    // } else {
     
    // }
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData(initialFormData);
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/institute/${id}`);
    console.log(formData.data.data);
    if (formData.status === 200) {
      setFormData({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };




  return (
    <div className="mb-4 flex justify-between items-center">
      <div className="w-full bg-white px-16 pb-6  rounded-lg  drop-shadow border">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
          <div className="text-center py-6">
            <input
              accept="image/*"
              id="image-upload"
              type="file"
              name="uploadInstituteLogo"
              style={{ display: "none", backgroundColor: "white" }}
              onChange={handleImageUpload}
            />
            <label htmlFor="image-upload">
              <IconButton
                color="#f5f5f5"
                aria-label="upload picture"
                component="span"
              >
                <div className="w-[140px] h-[140px] flex flex-col justify-center items-center border-2 border-primary rounded-full bg-white text-custom-green">
                  {/* Display the uploaded image */}

                  {imageUrl ? (
                    <img
                      alt="uploaded"
                      src={imageUrl}
                      className="w-36 h-36 rounded-full object-contain"
                    />
                  ) : (
                    // If no image is uploaded, show the upload icon and text
                    <>
                      {editMode ? (
                        <>
                          {" "}
                          <img
                            alt=""
                            src={`${ImageUrl}/instituteuploads/${formData.uploadInstituteLogo}`}
                            className="w-36 h-36 rounded-full object-contain"
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <img
                            alt="uploadcamera"
                            src={camera}
                            className="w-7 h-7 text-custom-green"
                          />
                          <span className="text-[15px] font-normal">
                            Upload Institute Logo
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </IconButton>
            </label>
          </div>

          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",

              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "12px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50",
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              // alignItems="center"
              sx={{ margin: "auto", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                // justifyContent="center"
                // alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Institute Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="instituteName"
                  value={formData.instituteName}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Institute Type"
                  variant="outlined"
                  fullWidth
                  name="instituteType"
                  value={formData.instituteType}
                  onChange={handleOnChange}
                  required
                  select
                >
                  <MenuItem value="School">School</MenuItem>
                  <MenuItem value="College">College</MenuItem>
                  <MenuItem value="Private Institute">
                    Private Institute
                  </MenuItem>
                </TextField>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Landline Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="landlineNumber"
                  value={formData.landlineNumber}
                  onChange={handleOnChange}
                  
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Email ID"
                  variant="outlined"
                  fullWidth
                  type="email"
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Address Line 1"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleOnChange}
                  required
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Area"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="area"
                  value={formData.area}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="State"
                  variant="outlined"
                  fullWidth
                  select
                  value={formData.state}
                  onChange={handleOnChange}
                  name="state"
                  required
                >
                  <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
                  <MenuItem value="Mumbai">Mumbai</MenuItem>
                  <MenuItem value="Karnataka">Karnataka</MenuItem>
                </TextField>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Pincode"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="No. of Faculties"
                  variant="outlined"
                  fullWidth
                  name="numberOfFaculties"
                  type="number"
                  value={formData.numberOfFaculties}
                  onChange={handleOnChange}
                  
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Cloud Space"
                  variant="outlined"
                  name="cloudSpace"
                  type="number"
                  required
                  fullWidth
                  value={formData.cloudSpace}
                  onChange={handleOnChange}
                  InputProps={{
                    endAdornment: (
                      <div className="flex items-center">
                        <Switch
                          checkedChildren={`TB`}
                          unCheckedChildren={`GB`}
                          onChange={handleCloudSpaceType}
                          checked={formData.cloudSpaceType}
                          className={"custom-switch-checked"}
                        />
                      </div>
                    ),
                  }}
                />
              </Stack>

              <Stack
                direction="column"
                // justifyContent="center"
                alignItems="end"
                width={"280px"}
                spacing={2}
              >
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Institute ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="instituteID"
                    value={formData.instituteID}
                    onChange={handleOnChange}
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Institute ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="instituteID"
                    value={formData.instituteID}
                    onChange={handleOnChange}
                    required
                  />
                )}
                

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Point Of Contact"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="pointOFContact"
                  value={formData.pointOFContact}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Mobile number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleOnChange}
                  required
                />

                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    // value={formData.password}
                    onChange={handleOnChange}
                    // required
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleOnChange}
                    required
                  />
                )}

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Address Line 2"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="addressLine2"
                  value={formData.addressLine2}
                  onChange={handleOnChange}
                  
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Country"
                  variant="outlined"
                  fullWidth
                  select
                  value={formData.country}
                  onChange={handleOnChange}
                  name="country"
                  required
                >
                  <MenuItem value="india">india</MenuItem>
                  <MenuItem value="america">america</MenuItem>
                  <MenuItem value="paris">paris</MenuItem>
                </TextField>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="District"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="district"
                  value={formData.district}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="No. of Branches"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="numberOfBranches"
                  value={formData.numberOfBranches}
                  onChange={handleOnChange}
                  
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="No. of Students"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="numberOfStudents"
                  value={formData.numberOfStudents}
                  onChange={handleOnChange}
                />

                <div className="flex items-center w-full justify-center gap-4 pt-3">
                  <img alt="vr" src={vr} className="w-[36px] h-[22px]" />
                  <Switch
                    checkedChildren={`ON`}
                    unCheckedChildren={`OFF`}
                    onChange={handleVr}
                    checked={formData.vrEnabled}
                    className={
                      formData.vrEnabled
                        ? "custom-switch-checked"
                        : "custom-switch-unchecked"
                    }
                  />
                </div>
              </Stack>
            </Stack>

            <div className="flex justify-center items-center space-x-8 pt-8 pb-2">
            {!editMode && (
              <button
                type="button"
                onClick={handleReset}
                className=" text-[12px] font-normal  px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                Reset
              </button>
            )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>
              {!editMode && (
                <button
                  type="button"
                  // onClick={() => {
                  //   handleSubmit();
                  // }}
                  className=" text-[12px] font-normal px-[20px] py-[10px]  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default InstituteForm;
