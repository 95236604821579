import React from "react";
import { HiMiniCake } from "react-icons/hi2";
import { FaBell } from "react-icons/fa";
import BirthdayUI from "./BirthdayUI";

const AnnouncementUI = () => {
  return (
    <div className="grid gap-2 border rounded-[10px] bg-white shadow-md w-full h-[260px] p-5">
      <div className="w-full flex flex-col h-full overflow-y-scroll">
        <h1 className="text-lg font-bold text-primary px-2">
          Announcements
        </h1>

        <div className="w-full flex flex-col justify-center items-center py-2">
          <div className="w-full flex flex-col justify-between rounded-md py-1 gap-1">
            <h2 className="text-[14px] font-semibold text-gray-600 px-2">
              Class Assessment assigning
            </h2>
            <div className="text-gray-500 text-[14px] font-medium px-2">
              Programming{" "}
              <span className="text-gray-300 text-[14px] font-bold mx-1">
                |
              </span>
              <span className="text-[14px] font-medium text-secondary">
                8:00 AM
              </span>
            </div>
            <hr className="w-full border border-gray-200" />
          </div>
          <div className="w-full flex flex-col justify-between rounded-md  py-1 gap-1">
            <h2 className="text-[14px] font-semibold text-gray-600 px-2">
              Class Assessment assigning
            </h2>
            <div className="text-gray-500 text-[14px] font-medium px-2">
              Programming{" "}
              <span className="text-gray-300 text-[14px] font-bold mx-1">
                |
              </span>
              <span className="text-[14px] font-medium text-secondary">
                8:00 AM
              </span>
            </div>
            <hr className="w-full border border-gray-200" />
          </div>
          <div className="w-full flex flex-col justify-between rounded-md  py-1 gap-1">
            <h2 className="text-[14px] font-semibold text-gray-600 px-2">
              Class Assessment assigning
            </h2>
            <div className="text-gray-500 text-[14px] font-medium px-2">
              Programming{" "}
              <span className="text-gray-300 text-[14px] font-bold mx-1">
                |
              </span>
              <span className="text-[14px] font-medium text-secondary">
                8:00 AM
              </span>
            </div>
            <hr className="w-full border border-gray-200" />
          </div>
        </div>
      </div>
      
    

      {/* <div className="flex ml-6">
        <div>
          <h2 className="text-sm font-semibold mt-1 text-primary">
            Student's Birthday
          </h2>

          <div className="flex items-start mt-1 mb-1">
            <div className="text-gray-500 text-xs font-medium">
              Suganya.G XI-A
            </div>
          </div>
        </div>

       
        <div className="mb-2 mt-1">
          <HiMiniCake className="text-rose-400 w-8 h-8 ml-4" />
        </div>
      </div> */}

      {/* <hr className="w-[200px] border border-gray-200 mb-1 mt-1 ml-4"></hr> */}

      {/* <div className="flex mt-1">
        <div className="ml-6">
          <FaBell className="w-8 h-8 items-center flex justify-center text-secondary" />
        </div>

        <div className="ml-2">
          <h2 className="text-xs ml-4 font-semibold text-gray-600">
            Meeting with Mr Lurah
          </h2>

          <div className="flex items-start mt-1 ml-4">
            <div className="text-gray-400 text-xs font-normal">
              09:20 AM
              <span className="ml-2 text-orange-300 font-normal">Due Soon</span>
            </div>
          </div>
        </div>
      </div>

      <hr className="w-[200px] items-center border border-gray-200 mb-1 mt-1 ml-4 "></hr> */}
    </div>
   


    // <div className="grid border rounded-[10px] bg-white shadow-md w-4/12 h-[300px] ">

    // </div>
  );
};

export default AnnouncementUI;
