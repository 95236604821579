import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Box, IconButton } from "@mui/material";
import { FaCamera } from "react-icons/fa";
import camera from "../Images/camera.png";
import Stack from "@mui/material/Stack";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;

const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const ELibraryForm = () => {
  const initialFormData = {
    uploadThumbnail:null,
    LibraryCategory: "",
    CourseName: "",
    title: "",
    authorName: "",
    description: "",
    keywords: "",
    externalLinks: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleReset = () => {
    setFormData(initialFormData);
  };

  // const handleThumbnailUpload = (e) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     uploadThumbnail: e.target.files[0],
  //   }));
  // };

  const [uploadThumbail, setUploadThumbnail] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);

  const handleThumbnailUpload = (e) => {
    const getUploadThumbail = e.target.files[0];
    // Check if a file was selected
    if (getUploadThumbail) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Set the image URL to the reader's result, which is the base64 data URL of the uploaded image
        setImageUrl(e.target.result);
      };

      reader.readAsDataURL(getUploadThumbail);
      setUploadThumbnail(getUploadThumbail);
    }
  };

  console.log(formData.uploadThumbnail);

  const handleSubmit = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

   

    const formAppendData = new FormData();

    formAppendData.append("uploadThumbnail", uploadThumbail);

    formAppendData.append("LibraryCategory", formData.LibraryCategory);
    formAppendData.append("CourseName", formData.CourseName);
    formAppendData.append("title", formData.title);
    formAppendData.append("authorName", formData.authorName);
    formAppendData.append("description", formData.description);
    formAppendData.append("keywords", formData.keywords);
    formAppendData.append("externalLinks", formData.externalLinks);
    
    if (formData.uploadThumbnail === null && uploadThumbail === null) {
      toast.warn("please upload your Thumbnail");
    } else if(uploadThumbail !== null){
      if (editMode) {
        axios
          .put(`${apiUrl}/elibrary/${id}`, formData, config)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/e-library");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/elibrary`, formAppendData, config)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/e-library");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }else if(formData.uploadThumbnail !== null){
      if (editMode) {
        axios
          .put(`${apiUrl}/elibrary/${id}`, formAppendData, config)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/e-library");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/elibrary`, formAppendData, config)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/e-library");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData(initialFormData);
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/elibrary/${id}`);
    console.log(formData.data.data);
    if (formData.status === 200) {
      setFormData({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  return (
    <div className="mb-4 flex justify-between items-center">
      <div className="w-full bg-white px-16 pb-6  rounded-lg  drop-shadow border">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
          <div className="text-center py-6">
            <input
              accept="image/*"
              id="image-upload"
              type="file"
              name="uploadThumbnail"
              style={{ display: "none", backgroundColor: "white" }}
              onChange={handleThumbnailUpload}
            />
            <label htmlFor="image-upload">
              <IconButton
                color="#f5f5f5"
                aria-label="upload picture"
                component="span"
              >
                {/* <Avatar sx={{ width: 150, height: 150 }}> */}

                <div className="w-[140px] h-[140px] flex flex-col justify-center items-center border-2 border-primary rounded-full bg-white text-primary">
                  {imageUrl ? (
                    <img
                      alt="uploaded"
                      src={imageUrl}
                      className="w-36 h-36 rounded-full object-contain"
                    />
                  ) : (
                    // If no image is uploaded, show the upload icon and text
                    <>
                      {editMode ? (
                        <>
                          {" "}
                          <img
                            alt=""
                            src={`${ImageUrl}/elibraryuploads/${formData.uploadThumbnail}`}
                            className="w-36 h-36 rounded-full object-contain"
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <img
                            alt="uploadcamera"
                            src={camera}
                            className="w-7 h-7 text-custom-green"
                          />
                          <span className="text-[15px] font-normal">
                            Resource Thumbnail
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </IconButton>
            </label>
          </div>

          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",

              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "12px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50",
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              // alignItems="center"
              sx={{ margin: "auto", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                // alignItems="center"
                width={"400px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Library Category"
                  variant="outlined"
                  fullWidth
                  name="LibraryCategory"
                  value={formData.LibraryCategory}
                  onChange={handleOnChange}
                  select
                  required
                >
                  <MenuItem value="Books">Books</MenuItem>
                  <MenuItem value="Journal Articles">Journal Articles</MenuItem>
                  <MenuItem value="Documents">Documents</MenuItem>
                  <MenuItem value="Videos">Videos</MenuItem>
                  <MenuItem value="AR Videos">AR Videos</MenuItem>
                  <MenuItem value="VR Videos">VR Videos</MenuItem>
                </TextField>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Course Name"
                  variant="outlined"
                  fullWidth
                  name="CourseName"
                  value={formData.CourseName}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Title"
                  variant="outlined"
                  fullWidth
                  name="title"
                  value={formData.title}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Author Name"
                  variant="outlined"
                  fullWidth
                  name="authorName"
                  value={formData.authorName}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 120,
                      width: "100%",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  name="description"
                  value={formData.description}
                  onChange={handleOnChange}
                  required
                  //defaultValue="Default Value"
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Keywords"
                  variant="outlined"
                  fullWidth
                  name="keywords"
                  select
                  value={formData.keywords}
                  onChange={handleOnChange}
                  required
                >
                  <MenuItem value="example 1">example 1</MenuItem>
                  <MenuItem value="example 2">example 2</MenuItem>
                  <MenuItem value="example 3">exapmle 3</MenuItem>
                </TextField>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="External Links"
                  variant="outlined"
                  fullWidth
                  name="externalLinks"
                  value={formData.externalLinks}
                  onChange={handleOnChange}
                  required
                />
              </Stack>
            </Stack>

            <div className="flex justify-center items-center space-x-8 pt-10 pb-2">
              <button
                type="button"
                onClick={handleReset}
                className=" text-[12px] font-normal  px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                Reset
              </button>
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>

              {!editMode && (
                <button
                  type="button"
                  // onClick={() => {
                  //   handleSubmit();
                  // }}
                  className=" text-[12px] font-normal px-[20px] py-[10px]  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default ELibraryForm;
