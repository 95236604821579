import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { generateDate, months } from "./Calendar";
import cn from "./Cn";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { Modal } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { dateCalendarClasses } from "@mui/x-date-pickers";

function Calendar() {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const currentDate = dayjs();
  const [today, setToday] = useState(currentDate);
  const [selectDate, setSelectDate] = useState();
  const [fetchedEvents, setFetchedEvents] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [tooltipContent, setTooltipContent] = useState(null);

  const [isEventModalVisible, setEventModalVisible] = useState(false);
  const LMS_ROLE = localStorage.getItem("lms_role");

  const initialEvent = {
    event: "",
    date: dayjs().format("DD/MM/YYYY"),
    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
      LMS_ROLE === "3" ||
      LMS_ROLE === "5" ||
      LMS_ROLE === "7" ||
      LMS_ROLE === "8"
        ? localStorage.getItem("Unique_id")
        : localStorage.getItem("institute_Unique_Id"),
  };
  const [events, setEvents] = useState(initialEvent);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/event/${localStorage.getItem("Unique_id")}`
      );
      const fetchedEvents = response.data.data;
      setFetchedEvents(fetchedEvents);
      console.log("Fetched Events:", fetchedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const addEvent = async (event) => {
    event.preventDefault();
    const formSendData = {
      ...events,
      date: selectDate.format("DD/MM/YYYY"),
    };
    axios.post(`${apiUrl}/event`, formSendData).then((res) => {
      if (res.data.status === 301) {
        toast.warn(res.data.message);
      } else {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          setEvents(initialEvent);
          fetchEvents();
        }
      }
    });

    setEventModalVisible(false);
  };
  // useEffect(() => {
  //   console.log(selectDate);
  // }, [selectDate]);

  useEffect(() => {
    fetchEvents();
    // console.log("trigger");
  }, []);

  return (
    <div className="w-full flex flex-col">
      <div className="w-full h-fit drop-shadow p-3 rounded-[4px] bg-gray-50 flex flex-col gap-2">
        <div className="flex justify-between items-center">
          <h1 className="select-none font-semibold text-sm text-[#105D50]">
            {months[today.month()]}, {today.year()}
          </h1>
          <div className="flex gap-2 items-center">
            <GrFormPrevious
              className="w-4 h-4 cursor-pointer hover:scale-105 transition-all"
              onClick={() => {
                setToday(today.month(today.month() - 1));
              }}
            />
            <h1
              className="cursor-pointer hover:scale-105 transition-all text-sm"
              onClick={() => {
                setToday(currentDate);
              }}
            >
              Today
            </h1>
            <GrFormNext
              className="w-4 h-4 cursor-pointer hover:scale-102 transition-all"
              onClick={() => {
                setToday(today.month(today.month() + 1));
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-7 gap-[4px]">
          {days.map((day, index) => (
            <h1
              key={index}
              className="text-xs text-center h-6 w-8 grid place-content-center text-gray-500 select-none"
            >
              {day}
            </h1>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-[4px] ">
          {generateDate(today.month(), today.year()).map(
            ({ date, currentMonth, today }, index) => (
              <div
                key={index}
                className="p-1 text-center relative group h-6 grid place-content-center text-xs border-t"
              >
                <h1
                  className={cn(
                    currentMonth ? "" : "text-gray-400",
                    today ? "bg-green-900 text-white" : "",
                    selectDate &&
                      selectDate.toDate().toDateString() ===
                        date.toDate().toDateString()
                      ? "bg-black text-white"
                      : "",
                    fetchedEvents.some((event) => {
                      // console.log('event.date:', event.date);
                      // console.log('date.format:', date.format('DD/MM/YYYY'));
                      return dayjs(event.date, "DD/MM/YYYY").isSame(
                        date,
                        "day"
                      );
                    })
                      ? "event-dot"
                      : "",
                    "h-5 w-5 rounded-full grid place-content-center hover:bg-black hover:text-white transition-all cursor-pointer select-none"
                  )}
                  onMouseEnter={() => setTooltipContent(date)}
                  // onMouseLeave={() => setTooltipContent(null)}
                  onClick={() => {
                    setSelectDate(date);
                    setEventModalVisible(true);
                  }}
                >
                  {date.date()}
                </h1>
                <div class="w-[200px] h-[190px] text-[11.5px] hidden group-hover:flex flex-col items-center gap-2  bg-white border drop-shadow text-sm p-2 rounded-md absolute top-0 right-[30px] overflow-y-scroll z-[10]">
                  {tooltipContent && (
                    <>
                      {fetchedEvents
                        .filter((event) =>
                          dayjs(event.date, "DD/MM/YYYY").isSame(
                            tooltipContent,
                            "day"
                          )
                        )
                        .map((event) => (
                          <div className="w-full h-fit border border-gray-300 rounded drop-shadow p-2">
                            <h1
                              key={event._id}
                              className="text-black text-start"
                            >
                              {event.event}
                            </h1>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
            )
          )}
        </div>
        <Modal
          title="Add Event"
          open={isEventModalVisible}
          onCancel={() => setEventModalVisible(false)}
          footer={[
            <button
              key="submit"
              className="py-3 px-4 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row justify-end gap-1"
              onClick={addEvent}
            >
              Submit
            </button>,
          ]}
        >
          <div className="form-group">
            <label htmlFor="eventName" className="label">
              Event Name:
            </label>
            <input
              type="text"
              name="event"
              id="eventName"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              value={events.event}
              onChange={(e) => setEvents({ ...events, event: e.target.value })}
            />
          </div>
        </Modal>
      </div>
      <div className="w-full ">
        {/* {tooltipContent && (
          <div className="absolute top-0 bottom-0 left-0 p-2 font-[14px] text-primary ">
          
            {fetchedEvents
              .filter((event) =>
                dayjs(event.date, "DD/MM/YYYY").isSame(tooltipContent, "day")
              )
              .map((event) => (
                <div key={event._id}>{event.event}</div>
              ))}
          </div>
        )} */}
      </div>
    </div>
  );
}

export default Calendar;
