import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Box, IconButton } from "@mui/material";
import { FaCamera } from "react-icons/fa";
import camera from "../Images/camera.png";
import Stack from "@mui/material/Stack";
// import Switch from "@mui/material/Switch";
import vr from "../Images/vr.png";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Container from "@mui/material/Container";
import FormGroup from "@mui/material/FormGroup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";

const Reports = () => {
  const initialFormData = {
    adminName: "",
    instituteType: "",
    branchType: "",
    from: "",
    to: "",
    list_accounts_that_use_vr: "",
    list_accounts_that_use_branches: "",
    highest_active_accounts: "",
    dormant_accounts: "",
    bandwidth_consumption: "",
    disk_usage: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const cloudSpaceOptions = ["GB", "TB"];
  const [cloudSpaceUnit, setCloudSpaceUnit] = useState(cloudSpaceOptions[0]);

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleReset = (e) => {
    // Define your reset logic here
  };

  const handleImageUpload = (e) => {
    // Define your image upload logic here
  };

  const handleSubmit = (e) => {
    // Define your submit logic here
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#105D50",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#105D50",
    },
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,

      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      backgroundColor: "white",
    },
  }));

  return (
    <div className="mb-4 flex justify-between items-center">
      <div className="w-full bg-white px-16 pb-6  rounded-lg  drop-shadow border">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
          <div className="pt-8">
            <Stack
              sx={{
                margin: "auto",
                width: "100%",
                display: "flex",
                alignItems: "center",

                "& > :not(style)": {},
                "& .MuiInputLabel-root": {
                  // Reduce label font size
                  fontSize: "12px",
                  color: "#105D50",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1.3px solid #105D50",
                  },
                  "&:hover fieldset": {
                    border: "1.3px solid #105D50",
                  },

                  "&.Mui-focused fieldset": {
                    border: "1.34px solid #105D50",
                    fontSize: "13px", // Set the outline color to yellow on focus
                  },
                },
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                // alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Admin Name"
                  variant="outlined"
                  fullWidth
                  name="adminName"
                  value={formData.adminName}
                  onChange={handleOnChange}
                  required
                  select
                >
                  <MenuItem value="male">Vignesh</MenuItem>
                  <MenuItem value="female">Suganya</MenuItem>
                  <MenuItem value="other">Gopal</MenuItem>
                </TextField>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Institute Type"
                  variant="outlined"
                  fullWidth
                  name="instituteType"
                  value={formData.instituteType}
                  onChange={handleOnChange}
                  required
                  select
                >
                   <MenuItem value="male">School</MenuItem>
                  <MenuItem value="female">College</MenuItem>
                  <MenuItem value="other">Private Institute</MenuItem>
                </TextField>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Branch Type"
                  variant="outlined"
                  fullWidth
                  name="branchType"
                  value={formData.branchType}
                  onChange={handleOnChange}
                  required
                  select
                >
                  <MenuItem value="male">School</MenuItem>
                  <MenuItem value="female">College</MenuItem>
                  <MenuItem value="other">Private Institute</MenuItem>
                </TextField>

             

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="From"
                    format="MM/DD/YYYY"
                    variant="outlined"
                    fullWidth
                    required
                    //defaultValue={dayjs('')}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="To"
                    format="MM/DD/YYYY"
                    variant="outlined"
                    fullWidth
                    //value={formData.dob}
                    required
                    //defaultValue={dayjs('')}
                  />
                </LocalizationProvider>

                <FormGroup>
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <div className="w-full flex flex-row items-center justify-center">
                          <div className="w-full flex flex-row items-center">
                            <label className="w-[200px] text-[13px] text-primary font-normal">
                              List accounts that use VR
                            </label>
                            <Android12Switch defaultChecked />
                          </div>
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true, // Keep the label in place even when clicking the text field.
                      style: { fontSize: "12px" },
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <div className="w-full flex flex-row items-center justify-center">
                          <div className="w-full flex flex-row items-center">
                            <label className="w-[200px] text-[13px] text-primary font-normal">
                              List accounts that use branches
                            </label>
                            <Android12Switch defaultChecked />
                          </div>
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true, // Keep the label in place even when clicking the text field.
                      style: { fontSize: "12px" },
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <div className="w-full flex flex-row items-center justify-center">
                          <div className="w-full flex flex-row items-center">
                            <label className="w-[200px] text-[13px] text-primary font-normal">
                              Highest active accounts
                            </label>
                            <Android12Switch defaultChecked />
                          </div>
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true, // Keep the label in place even when clicking the text field.
                      style: { fontSize: "12px" },
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <div className="w-full flex flex-row items-center justify-center">
                          <div className="w-full flex flex-row items-center">
                            <label className="w-[200px] text-[13px] text-primary font-normal">
                              Dormant accounts
                            </label>
                            <Android12Switch defaultChecked />
                          </div>
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true, // Keep the label in place even when clicking the text field.
                      style: { fontSize: "12px" },
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <div className="w-full flex flex-row items-center justify-center">
                          <div className="w-full flex flex-row items-center">
                            <label className="w-[200px] text-[13px] text-primary font-normal">
                              Bandwidth consumption
                            </label>
                            <Android12Switch defaultChecked />
                          </div>
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true, // Keep the label in place even when clicking the text field.
                      style: { fontSize: "12px" },
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <div className="w-full flex flex-row items-center justify-center">
                          <div className="w-full flex flex-row items-center">
                            <label className="w-[200px] text-[13px] text-primary font-normal">
                              Disk usage
                            </label>
                            <Android12Switch defaultChecked />
                          </div>
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true, // Keep the label in place even when clicking the text field.
                      style: { fontSize: "12px" },
                    }}
                  />
                </FormGroup>

                <div className="w-full flex justify-center items-center space-x-8">
                  <button
                    type="submit"
                    className="w-full text-[12px] font-normal py-[12px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
                  >
                    Generate
                  </button>
                </div>
              </Stack>
            </Stack>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Reports;
