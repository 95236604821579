import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity, faGraduationCap } from "@fortawesome/free-solid-svg-icons";

import Support from "./Support";
import CollegeSupport from "./CollegeSupport";

const SupportType = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [isToggle, setIsToggle] = useState(false);

  const cardData = [
    { title: "Institute", icon: faUniversity },
    {
      title: "College",
      icon: faGraduationCap,
    },
    // { title: "Exam", link: `/homepage/My Course/Exam`, icon: exams },
  ];

  const handleToggle = () => {
    setIsToggle(!isToggle);
  };

  const handleCardClick = (component) => {
    setSelectedComponent(component);
    handleToggle();
  };

  return (
    <div className="bg-white h-auto rounded-xl border drop-shadow ">
      <div className="flex flex-col justify-between mx-auto max-w-screen-xl">
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-16">
            {cardData.map((card, index) => (
              <div
                key={index}
                onClick={() => handleCardClick(card.title)}
                className="hover:bg-gray-100 bg-white border rounded-2xl h-[60px] w-[200px] mt-8 transition-colors duration-300 flex items-center justify-center shadow-md cursor-pointer"
              >
                <FontAwesomeIcon icon={card.icon} className="w-5 h-5 mr-2" />
                <p className="font-semibold text-center text-md">
                  {card.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-8">
        {selectedComponent === "Institute" && <Support />}
        {selectedComponent === "College" && <CollegeSupport />}
      </div>
    </div>
  );
};

export default SupportType;
