import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Box, IconButton } from "@mui/material";
import { FaCamera } from "react-icons/fa";
import camera from "../Images/camera.png";
import Stack from "@mui/material/Stack";
// import Switch from "@mui/material/Switch";
import vr from "../Images/vr.png";
import { styled } from "@mui/material/styles";
import { Switch, Space } from "antd";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import "../App.css";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;

const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const AdminForm = () => {
  const initialFormData = {
    uploadProfilePic: null,
    adminId: "",
    name: "",
    emailId: "",
    password: "",
    mobileNumber: "",
    permission: {
      add: false,
      edit: false,
      delete: false,
    },
    reportGeneration: {
      add: false,
    },
  };



  const [formData, setFormData] = useState(initialFormData);

  console.log(formData);

  const [profilePic, setProfilePic] = useState(null);

  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleReset = () => {
    axios
      .post(`${apiUrl}/superadmin`, {
        name: "whytap",
        emailId: "whytap@gmail.com",
        password: "why123",
      })
      .then((res) => res.data);
    // setFormData(initialFormData);
  };

  const [imageUrl, setImageUrl] = useState(null);

  const handleProfilePicUpload = (e) => {
    const uploadAdminLogo = e.target.files[0];
    // Check if a file was selected
    if (uploadAdminLogo) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Set the image URL to the reader's result, which is the base64 data URL of the uploaded image
        setImageUrl(e.target.result);
      };

      reader.readAsDataURL(uploadAdminLogo);
      setProfilePic(uploadAdminLogo);
    }
  };

  console.log(formData)

  const handleSubmit = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formAppendData = new FormData();
    formAppendData.append("uploadProfilePic", profilePic);
    formAppendData.append("adminId", formData.adminId);
    formAppendData.append("name", formData.name);
    formAppendData.append("emailId", formData.emailId);
    formAppendData.append("password", formData.password);
    formAppendData.append("mobileNumber", formData.mobileNumber);
    formAppendData.append("permissionAdd", formData.permission.add);
    formAppendData.append("permissionEdit", formData.permission.edit);
    formAppendData.append("permissionDelete", formData.permission.delete);
    formAppendData.append("reportGenerationAdd", formData.reportGeneration.add);

    const formSendData = {
      uploadProfilePic: formData.uploadProfilePic,
      adminId: formData.adminId,
      name: formData.name,
      emailId: formData.emailId.toLowerCase(),
      password: formData.password,
      mobileNumber: formData.mobileNumber,
      permissionAdd: formData.permission.add,
      permissionEdit: formData.permission.edit,
      permissionDelete: formData.permission.delete,
      reportGenerationAdd: formData.reportGeneration.add,
    };

    if (formData.uploadProfilePic === null && profilePic === null) {
      toast.warn("please upload your Profile Pic");
    } else if (profilePic !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/admin/${id}`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/admins");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/admin`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/admins");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    } else if (formData.uploadProfilePic !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/admin/${id}`, formSendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/admins");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/admin`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/admins");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };

  // const handlePermissionAdd = (checked) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     permissions: {
  //       ...prev.permissions,
  //       add: checked,
  //     },
  //   }));
  // };

  // const handlePermissionEdit = (checked) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     permissions: {
  //       ...prev.permissions,
  //       edit: checked,
  //     },
  //   }));
  // };

  // const handlePermissionDelete = (checked) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     permissions: {
  //       ...prev.permissions,
  //       delete: checked,
  //     },
  //   }));
  // };

  const handleInstituteAdd = (checked) => {
    setFormData((prev) => ({
      ...prev,
      permission: {
        ...prev.permission,
        add: checked,
      },
    }));
  };

  const handleInstituteEdit = (checked) => {
    setFormData((prev) => ({
      ...prev,
      permission: {
        ...prev.permission,
        edit: checked,
      },
    }));
  };

  const handleInstituteDelete = (checked) => {
    setFormData((prev) => ({
      ...prev,
      permission: {
        ...prev.permission,
        delete: checked,
      },
    }));
  };

  const handleReportGenerationAdd = (checked) => {
    setFormData((prev) => ({
      ...prev,
      reportGeneration: {
        ...prev.reportGeneration,
        add: checked,
      },
    }));
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData(initialFormData);
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/admin/${id}`);
    console.log(formData.data.data);
    if (formData.status === 200) {
      setFormData({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  return (
    <div className="mb-4 flex justify-between items-center">
      <div className="w-full bg-white px-16 pb-6  rounded-lg drop-shadow border">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
          <div className="text-center py-6">
            <input
              accept="image/*"
              id="image-upload"
              type="file"
              style={{ display: "none", backgroundColor: "white" }}
              onChange={handleProfilePicUpload}
            />
            <label htmlFor="image-upload">
              <IconButton
                color="#f5f5f5"
                aria-label="upload picture"
                component="span"
              >
                {/* <Avatar sx={{ width: 150, height: 150 }}> */}

                <div className="w-[140px] h-[140px] flex flex-col justify-center items-center border-2 border-primary rounded-full bg-white text-primary">
                  {imageUrl ? (
                    <img
                      alt="uploaded"
                      src={imageUrl}
                      className="w-36 h-36 rounded-full object-contain"
                    />
                  ) : (
                    // If no image is uploaded, show the upload icon and text
                    <>
                      {editMode ? (
                        <>
                          {" "}
                          <img
                            alt=""
                            src={`${ImageUrl}/adminuploads/${formData.uploadProfilePic}`}
                            className="w-36 h-36 rounded-full object-contain"
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <img
                            alt="uploadcamera"
                            src={camera}
                            className="w-7 h-7 text-custom-green"
                          />
                          <span className="text-[15px] font-normal">
                            Upload Profile Picture
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>

                {/* </Avatar> */}
              </IconButton>
            </label>
          </div>

          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              // alignItems: "center",

              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "12px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50",
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            {/* <Grid container spacing={5} > */}

            <Stack
              direction="row"
              justifyContent="center"
              // alignItems="center"
              sx={{ margin: "auto", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                // justifyContent="center"
                // alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Name"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formData.name}
                  onChange={handleOnChange}
                  required
                />

                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    // value={formData.password}
                    onChange={handleOnChange}
                    // required
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleOnChange}
                    required
                  />
                )}

                {/* <FormGroup>
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="permissions *"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <div className="w-full flex flex-row items-center">
                          <div className="flex flex-row items-center">
                            <label className="text-[13px] text-primary font-normal mx-1">
                              Add
                            </label>
                            <Switch
                              onChange={handlePermissionAdd}
                              checked={formData.permissions.add}
                              className={
                                formData.permissions.add
                                  ? "custom-switch-checked"
                                  : "custom-switch-unchecked"
                              }
                            />
                          </div>
                          <div className="flex flex-row items-center">
                            <label className="text-[13px] text-primary font-normal mx-1">
                              Edit
                            </label>
                            <Switch
                              onChange={handlePermissionEdit}
                              checked={formData.permissions.edit}
                              className={
                                formData.permissions.edit
                                  ? "custom-switch-checked"
                                  : "custom-switch-unchecked"
                              }
                            />
                          </div>
                          <div className="flex flex-row items-center">
                            <label className="text-[13px] text-primary font-normal mx-1">
                              Delete
                            </label>
                            <Switch
                              onChange={handlePermissionDelete}
                              checked={formData.permissions.delete}
                              className={
                                formData.permissions.delete
                                  ? "custom-switch-checked"
                                  : "custom-switch-unchecked"
                              }
                            />
                          </div>
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true, // Keep the label in place even when clicking the text field.
                      style: { fontSize: "12px" },
                    }}
                  />
                </FormGroup> */}
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Admin ID"
                    variant="outlined"
                    fullWidth
                    name="adminId"
                    value={formData.adminId}
                    onChange={handleOnChange}
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Admin ID"
                    variant="outlined"
                    fullWidth
                    name="adminId"
                    value={formData.adminId}
                    onChange={handleOnChange}
                    required
                  />
                )}

                <FormGroup>
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Report Generation *"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <div className="w-full flex flex-row items-center justify-center">
                          <div className="flex flex-row items-center gap-1 mx-24">
                            <label className="text-[13px] text-primary font-normal">
                              Add
                            </label>
                            <Switch
                              onChange={handleReportGenerationAdd}
                              checked={formData.reportGeneration.add}
                              className={
                                formData.reportGeneration.add
                                  ? "custom-switch-checked"
                                  : "custom-switch-unchecked"
                              }
                            />
                          </div>
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true, // Keep the label in place even when clicking the text field.
                      style: { fontSize: "12px" },
                    }}
                  />
                </FormGroup>
              </Stack>

              <Stack
                direction="column"
                // justifyContent="center"
                // alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Email ID"
                  variant="outlined"
                  fullWidth
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleOnChange}
                  required
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Mobile Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleOnChange}
                  required
                />

                <FormGroup>
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Institute *"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <div className="w-full flex flex-row items-center ">
                          <div className="flex flex-row items-center">
                            <label className="text-[13px] text-primary font-normal mx-1">
                              Add
                            </label>
                            <Switch
                              onChange={handleInstituteAdd}
                              checked={formData.permission.add}
                              className={
                                formData.permission.add
                                  ? "custom-switch-checked"
                                  : "custom-switch-unchecked"
                              }
                            />
                          </div>
                          <div className="flex flex-row items-center">
                            <label className="text-[13px] text-primary font-normal mx-1">
                              Edit
                            </label>
                            <Switch
                              onChange={handleInstituteEdit}
                              checked={formData.permission.edit}
                              className={
                                formData.permission.edit
                                  ? "custom-switch-checked"
                                  : "custom-switch-unchecked"
                              }
                            />
                          </div>
                          <div className="flex flex-row items-center">
                            <label className="text-[13px] text-primary font-normal mx-1">
                              Delete
                            </label>
                            <Switch
                              onChange={handleInstituteDelete}
                              checked={formData.permission.delete}
                              className={
                                formData.permission.delete
                                  ? "custom-switch-checked"
                                  : "custom-switch-unchecked"
                              }
                            />
                          </div>
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true, // Keep the label in place even when clicking the text field.
                      style: { fontSize: "12px" },
                    }}
                  />
                </FormGroup>
              </Stack>
            </Stack>

            <div className="flex justify-center items-center space-x-8 pt-10 pb-2">
            {!editMode && (
              <button
                type="button"
                onClick={handleReset}
                className=" text-[12px] font-normal  px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                Reset
              </button>
               )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>

              {!editMode && (
                <button
                  type="button"
                  // onClick={() => {
                  //   handleSubmit();
                  // }}
                  className=" text-[12px] font-normal px-[20px] py-[10px]  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default AdminForm;
