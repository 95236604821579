import React from "react";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import upload from "../Images/upload.png";
import download from "../Images/download.png";
import chat from "../Images/chat.png";
import axios from "axios";
import { Switch } from "antd";
import { toast } from "react-toastify";

const Support = () => {
  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);
  const [status, setStatus] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [statuses, setStatuses] = useState({});
  const [statusCounts, setStatusCounts] = useState({
    open: 0,
    progress: 0,
    completed: 0,
    rejected: 0,
    unassigned: 0,
  });

  const getStatusColor = (status) => {
    switch (status) {
      case "open":
        return "red"; // Set the color based on the status
      case "progress":
        return "yellow";
      case "completed":
        return "green";
      case "rejected":
        return "gray";
      default:
        return "transparent";
    }
  };

  const columns = [
    {
      name: <h1 className="text-white pl-2 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
      sortable: true, // Enable sorting for this column
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-4 w-[100px]">Date</h1>,
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      name: <h1 className="text-white pl-5 w-[100px]">ID</h1>,
      selector: (row) => row.ticketNumber,
      sortable: true,
      width: "170px",
    },

    {
      name: <h1 className="text-white pl-3 w-[300px]">Name</h1>,
      selector: (row) => row.instituteUniqueId,
      sortable: true,
      width: "300px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Mobile Number</h1>,
      selector: (row) => row.mobileNumber,
      sortable: true,
      width: "150px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Point of Contact</h1>,
      selector: (row) => row.pointOfContact,
      sortable: true,
      width: "150px",
    },
    {
      name: <h1 className="text-white pl-4 w-[400px]">Subject</h1>,
      selector: (row) => row.subject,
      sortable: true,
      width: "400px",
    },
    {
      name: <h1 className="text-white pl-4 w-[400px]">Support Type</h1>,
      selector: (row) => row.supportType,
      sortable: true,
      width: "100px",
    },
    {
      name: <h1 className="text-white pl-3 w-[100px]">Priority</h1>,
      selector: (row) => row.priority,
      sortable: true,
      width: "100px",
    },

    {
      name: <h1 className="text-white pl-3 w-[150px]">Status</h1>,
      selector: (row) => (
        <div className="relative">
          <div
            className="status-dot"
            style={{
              backgroundColor: getStatusColor(statuses[row.ticketNumber]),
            }}
          ></div>
          <div className="status-dropdown">
            <select
              value={statuses[row.ticketNumber]}
              onChange={(e) =>
                handleStatusChange(row.ticketNumber, e.target.value)
              }
            >
              <option value="">Select Status</option>
              <option value="progress">Progress</option>
              <option value="completed">Closed</option>
            </select>
          </div>
        </div>
      ),
      width: "150px",
    },
    {
      name: <h1 className="text-white pl-3 w-[100px]">Chat</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-2">
            <img src={chat} alt="chat" className="w-6 h-6" />
          </div>
        </>
      ),
      width: "100px",
    },
  ];

  const handleStatusUpdate = async (id, newStatus) => {
    try {
      const response = await axios.put(`${apiUrl}/supportform/${id}`, {
        status: newStatus,
      });

      if (response.status === 200) {
        // Update the status in your local state
        const updatedData = filteredData.map((item) => {
          if (item._id === id) {
            return { ...item, status: newStatus };
          }
          return item;
        });

        setFilteredData(updatedData);
        toast.success("Status updated successfully");
      }
    } catch (error) {
      toast.error("Error updating status: " + error.message);
    }
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/supportform`)
      .then((res) => {
        const data = res.data.data;
        // Initialize statuses for each row with an empty object
        const initialStatuses = data.reduce((acc, item) => {
          acc[item.ticketNumber] = ""; // Empty status for each row
          return acc;
        }, {});
        setStatuses(initialStatuses);
        setFilteredData(data);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${apiUrl}/supportform`)
      .then((res) => {
        const data = res.data.data;
        console.log("API Response:", data); // Log the API response
        setFilteredData(data); // Update filteredData with fetched data
      })
      .catch((error) => {
        console.log("Error fetching data:", error); // Log any errors
      });
  }, [status]);

  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        Title: item.title,
        "Author Name": item.authorName,
        "Library Category": item.LibraryCategory,
      }));
      return csvData;
    }
    return [];
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/supportform`)
      .then((res) => {
        const filtered = res.data.data.filter((item) =>
          item.Name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchTerm, status]);

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  const getStatusCounts = () => {
    // Count the occurrences of each status in the statuses state
    const counts = {
      open: 0,
      progress: 0,
      completed: 0,
      rejected: 0,
      unassigned: 0, // Initialize the unassigned count
    };

    for (const status in statuses) {
      if (statuses[status] === "progress") {
        counts.progress++;
      } else if (statuses[status] === "completed") {
        counts.completed++;
      } else {
        counts.unassigned++; // Increment the unassigned count for tickets without a status
      }
    }

    return counts;
  };

  useEffect(() => {
    const counts = getStatusCounts();
    setStatusCounts(counts);
  }, [statuses]);

  // const handleStatusChange = (ticketNumber, newStatus) => {
  //   // Update the status locally first
  //   setStatuses({ ...statuses, [ticketNumber]: newStatus });

  //   // Make an API request to update the status in the database
  //   axios
  //     .put(`${apiUrl}/supportform/${ticketNumber}`, { status: newStatus })
  //     .then((res) => {
  //       // Handle the success response if needed
  //       console.log("Status updated successfully");

  //       // Fetch the updated data from the backend
  //       axios
  //         .get(`${apiUrl}/supportform`)
  //         .then((res) => {
  //           const data = res.data.data;
  //           // Update the filteredData with the new data
  //           setFilteredData(data);
  //         })
  //         .catch((error) => {
  //           console.log("Error fetching updated data:", error);
  //         });
  //     })
  //     .catch((error) => {
  //       // Handle the error if the update fails
  //       console.error("Error updating status:", error);
  //       // Rollback the local status change in case of an error
  //       setStatuses({ ...statuses, [ticketNumber]: statuses[ticketNumber] });
  //     });
  // };

  const handleStatusChange = async (ticketNumber, newStatus) => {
    try {
      // Make an API request to update the status in the database
      const response = await axios.put(`${apiUrl}/supportform/${ticketNumber}`, {
        status: newStatus,
      });

      if (response.status === 200) {
        // Update the statuses state with the new status
        setStatuses((prevStatuses) => ({
          ...prevStatuses,
          [ticketNumber]: newStatus,
        }));

        // Save the statuses to localStorage
        localStorage.setItem("statuses", JSON.stringify(statuses));

        toast.success("Status updated successfully");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Error updating status: " + error.message);
    }
  };

  useEffect(() => {
    // Retrieve statuses from localStorage on component mount
    const storedStatuses = localStorage.getItem("statuses");
    if (storedStatuses) {
      setStatuses(JSON.parse(storedStatuses));
    }

    // Fetch initial data from the backend
    axios.get(`${apiUrl}/supportform`).then((res) => {
      const data = res.data.data;
      setFilteredData(data);

      // Initialize statuses for each row with an empty object
      const initialStatuses = data.reduce((acc, item) => {
        acc[item.ticketNumber] = item.status;
        return acc;
      }, {});
      setStatuses(initialStatuses);
    });
  }, []);

  useEffect(() => {
    // Save the statuses to localStorage whenever statuses change
    localStorage.setItem("statuses", JSON.stringify(statuses));
  }, [statuses]);

  useEffect(() => {
    // Filter data based on search term
    axios
      .get(`${apiUrl}/supportform`)
      .then((res) => {
        const filtered = res.data.data.filter((item) =>
          item.Name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchTerm]);



  return (
    <>
      <div className="flex flex-col  w-full ">
        <div className="W-full h-fit bg-white flex flex-col items-center gap-7 rounded-md pt-5 px-1 drop-shadow border">
          <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
            <div className="w-full flex flex-row items-center gap-8 justify-between">
              <div class="w-full flex flex-col">
                <div class="flex flex-row items-center gap-8">
                  <div class="w-[160px] p-1.5 border border-primary cursor-pointer flex flex-row items-center justify-between rounded-md">
                    <span class="text-sm font-semibold text-primary">
                      UNASSIGNED
                    </span>
                    <span class="px-3 py-1 bg-orange-500 rounded-md text-sm text-white">
                      {statusCounts.unassigned}
                    </span>
                  </div>
                  <div class="w-[160px] p-1.5 border border-primary cursor-pointer flex flex-row items-center justify-between rounded-md">
                    <span class="text-sm font-semibold text-primary">
                      PROGRESS
                    </span>
                    <span class="px-3 py-1 bg-yellow-500 rounded-md text-sm text-white">
                      {statusCounts.progress}
                    </span>
                  </div>
                  <div class="w-[160px] p-1.5 border border-primary cursor-pointer flex flex-row items-center justify-between rounded-md">
                    <span class="text-sm font-semibold text-primary">
                      CLOSED
                    </span>
                    <span class="px-3 py-1 bg-blue-600 rounded-md text-sm text-white">
                      {statusCounts.completed}
                    </span>
                  </div>
                </div>
              </div>

              <div className="relative">
                <input
                  placeholder="Search"
                  type="text"
                  required
                  className="px-3 py-2.5 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                  onChange={(event) => setSearchTerm(event.target.value)}
                  value={searchTerm}
                />
                <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-secondary" />
              </div>
            </div>

            <div className="flex flex-row items-center gap-2">
              <div class="relative group flex items-center">
                <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                  {" "}
                  <img
                    alt="upload"
                    src={upload}
                    className="w-5 h-5 mx-auto mt-2 cursor-pointer"
                  />
                </span>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Upload
                </div>
              </div>
              <div class="relative group flex items-center">
                <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                  {" "}
                  <img
                    alt="download"
                    src={download}
                    className="w-5 h-5 mx-auto mt-2 cursor-pointer"
                    onClick={handleCsv}
                  />
                </span>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Download
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <DataTable
              fixedHeader={true}
              columns={columns}
              data={filteredData}
              selectableRows
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              fixedHeaderScrollHeight="358px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
