
import { PiHandWavingFill } from "react-icons/pi";
import { BsCalendar2CheckFill } from "react-icons/bs";
import image1 from "../Images/30.png";
import image2 from "../Images/29.png";
import image3 from "../Images/28.png";
import { FaCircleUser } from "react-icons/fa6";
import { CircularProgressbar } from "react-circular-progressbar";
import { HiMiniCake } from "react-icons/hi2";
import { FaBell } from "react-icons/fa";
import { PiNotepadFill } from "react-icons/pi";
import Speedometer from "react-speedometer";
import SProfile from "./Profile";
import GaugeChart from "react-gauge-chart";
import AnnouncementUI from "./AnnouncementUI";
import axios from "axios";
import { useState, useEffect } from "react";






const apiUrl = process.env.REACT_APP_API_URL;


const SuperAdminHomePage = () => {
  const LMS_ROLE = localStorage.getItem("lms_role");



  const [speed, setSpeed] = useState(0);
  const presentPercentage = 75;

  const [item, setItem] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if(LMS_ROLE === '1'){
      axios
      .get(`${apiUrl}/institute`)
      .then((res) => {
        setItem(res.data.data);
        console.log(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }else{
      axios
      .get(`${apiUrl}/institutebyuniqueid/${localStorage.getItem("Unique_id")}`)
      .then((res) => {
        setItem(res.data.data);
        console.log(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
    
  }, [status]);

  const collegeCount = item.filter((i) => i.instituteType === "College").length;
  const privateInstituteCount = item.filter((i) => i.instituteType === "Private Institute").length;
  const schoolCount = item.filter((i) => i.instituteType === "School").length;


  const gaugeOptions = {
    // Customize options as needed
    colors: ["#45A8A3", "#105D50"], // Example colors for different ranges
    percent: 0.8, // Adjust this value to set the gauge needle position (between 0 and 1)
    textColor: "#333", // Text color
    arcPadding: 0.1, // Padding between arcs
    needleColor: "#F5A70D",
    needleBaseColor: "#F5A70D",
  };

  return (
    <div className="w-full h-auto flex flex-row justify-center gap-4 pb-4">
      <div className="w-[70%] flex flex-col h-auto gap-5 border rounded-lg drop-shadow-sm p-4">
        <div>
          <h1 className="font-medium text-xl flex items-center mb-1">
            Hello Superadmin
            <span className="text-orange-300 ml-4 text-xl">
              <PiHandWavingFill />
            </span>
          </h1>
          <div className="text-gray-500 text-sm font-normal mb-2">
            Let’s learn something new today!
          </div>
        </div>

        <div className="w-full h-auto flex flex-row justify-between items-center my-4">
          {/* Card 1 */}
          <div className="flex flex-row justify-between items-center px-4  border-primary border rounded-lg shadow">
            <img src={image1} alt="Image 1" className="w-20 h-auto flex mr-4" />
            <div className="text-container text-center flex flex-col ">
              <span className="text-primary font-bold">Private</span>
              <span className="text-3xl">{privateInstituteCount}</span>
            </div>
          </div>

          {/* Card 2 */}
          <div className="flex flex-row justify-between items-center px-4  border-primary border rounded-lg shadow">
            <img src={image2} alt="Image 1" className="w-20 h-auto flex mr-4" />
            <div className="text-container text-center flex flex-col ">
              <span className="text-primary font-bold">College</span>
              <span className="text-3xl">{collegeCount}</span>
            </div>
          </div>

          {/* Card 3 */}
          <div className="flex flex-row justify-between items-center px-4  border-primary border rounded-lg shadow">
            <img src={image3} alt="Image 1" className="w-20 h-auto flex mr-4" />
            <div className="text-container text-center flex flex-col ">
              <span className="text-primary font-bold">School</span>
              <span className="text-3xl">{schoolCount}</span>
            </div>
          </div>
        </div>

        <div className="w-full flex h-auto flex-row justify-center items-center gap-10">
          <div className="w-[60%] flex flex-col justify-start items-start">
            <span className="px-4 py-2 bg-primary text-center text-white rounded-t-lg">
              Your Documents
            </span>
            <div className="w-full flex flex-col border border-primary rounded-b-lg h-[250px] rounded-tr-lg p-4">
              <div className="mt-3  p-2 rounded-md shadow-md justify-between  flex gap-6  ">
                <div className="font-normal text-xs flex flex-col  text-gray-800 ">
                  <div className="flex items-center gap-4">
                    <span className="  text-fuchsia-300 border p-1 border-fuchsia-100 bg-fuchsia-100 rounded-md  text-sm">
                      <PiNotepadFill className="w-5 h-5"/>
                    </span>
                    <div className="flex  flex-col">
                      <span>Submisson NLP Programming</span>
                      <span className="text-gray-400">04 Jan, 09:20 AM</span>
                    </div>
                  </div>
                </div>
                <div>
                  <button className=" border p-1 flex border-gray-300 bg-slate-200 text-gray-400 text-xs rounded-md">
                    Submitted
                  </button>
                </div>
              </div>
              <div className="mt-3  p-2 rounded-md shadow-md justify-between  flex gap-6  ">
                <div className="font-normal text-xs flex flex-col  text-gray-800 ">
                  <div className="flex items-center gap-4">
                    <span className="  text-fuchsia-300 border p-1 border-fuchsia-100 bg-fuchsia-100 rounded-md  text-sm">
                      <PiNotepadFill className="w-5 h-5"/>
                    </span>
                    <div className="flex  flex-col">
                      <span>Submisson NLP Programming</span>
                      <span className="text-gray-400">04 Jan, 09:20 AM</span>
                    </div>
                  </div>
                </div>
                <div>
                  <button className=" border p-1 flex border-gray-300 bg-slate-200 text-gray-400 text-xs rounded-md">
                    Submitted
                  </button>
                </div>
              </div>
              <div className="mt-3  p-2 rounded-md shadow-md justify-between  flex gap-6  ">
                <div className="font-normal text-xs flex flex-col  text-gray-800 ">
                  <div className="flex items-center gap-4">
                    <span className="  text-fuchsia-300 border p-[4px] border-fuchsia-100 bg-fuchsia-100 rounded-md  text-sm">
                      <PiNotepadFill className="w-5 h-5"/>
                    </span>
                    <div className="flex  flex-col">
                      <span>Submisson NLP Programming</span>
                      <span className="text-gray-400">04 Jan, 09:20 AM</span>
                    </div>
                  </div>
                </div>
                <div>
                  <button className=" border p-1 flex border-gray-300 bg-slate-200 text-gray-400 text-xs rounded-md">
                    Submitted
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[40%] flex flex-col justify-start items-start">
            <span className="px-4 py-2 bg-primary text-center text-white rounded-t-lg">
              Performance
            </span>

            <div className="flex flex-col border h-[250px] border-[#105D50] w-full rounded-b-lg rounded-tr-lg drop-shadow-lg p-4 ">
              <div className="flex flex-col-2 justify-between">
                <div className="flex">
                  <div className="h-[15px] w-[15px] rounded-[3px] bg-[#45A8A3]"></div>
                  <p className="text-gray-500 text-sm ml-2">34mb spent</p>
                </div>
                <div className="flex flex-row justify-center items-centers gap-[7px]">
                  <div className="h-[15px] w-[15px] rounded-[3px] bg-[#105D50] mt-[1px]"></div>
                  <p className="text-gray-500 text-sm">20tb spent</p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center mt-8">
                <GaugeChart id="speedometer" {...gaugeOptions} />
                <p>
                  Storage spent: <b>8.966</b>
                </p>
              </div>
            </div>
          </div>
        </div>


        {/* ...................... Todays Birthday .......................................... */}
      </div>
      <div className="w-[30%] flex flex-col h-auto justify-center items-center gap-4">
        <SProfile />
        <AnnouncementUI />
      </div>
    </div>
  );
};

export default SuperAdminHomePage;
