import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import abhis from "../Images/abhisbg.png";
import loginImg from "../Images/study.jpeg.jpg";
import { FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
import key from "../Images/key.png";
import { toast } from "react-toastify";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const Login = () => {
  const initialState = {
    email: "",
    password: "",
  };
  const [visible, setVisible] = useState(false);
  const [form, setForm] = useState(initialState);

  const Navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/auth/login`, form);

      if (response.data.error === false && response.data.data.status === 1) {
        if (response.data.data.role === 1 || response.data.data.role === 2) {
          localStorage.setItem("token_id", response.data.accessToken);
          localStorage.setItem("lms_role", response.data.data.role);
          // localStorage.setItem("refreshtoken_id", response.data.refreshToken);
          localStorage.setItem("Unique_id", response.data.data.id);
          localStorage.setItem("auth", response.data.auth);

          if (response.data.data.role === 2) {
            localStorage.setItem(
              "permission_add",
              response.data.data.permission.add
            );
            localStorage.setItem(
              "permission_edit",
              response.data.data.permission.edit
            );
            localStorage.setItem(
              "permission_delete",
              response.data.data.permission.delete
            );
            localStorage.setItem(
              "reportpermission_add",
              response.data.data.reportGeneration.add
            );
          }

          Navigate(`/homepage/home`);
          toast.success(response.data.message, {
            position: "bottom-right",
          });

          setForm(initialState);
        } else {
          toast.error("Email is not found");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error, {
        position: "top-center",
      });
    }
  };

  return (
    <div className="grid grid-col-1 sm:grid-cols-2 h-screen w-full">
      <div className="hidden sm:block ">
        <img className="w-full h-full object-cover" src={loginImg} alt="" />
      </div>

      <div className="bg-[#FAFAFA] flex flex-col  justify-center">
        <form
          className="w-[400px] mx-auto h-fit bg-[#EEEEEE] px-8   pt-8 pb-4 rounded-2xl"
          onSubmit={handleSubmit}
        >
          <div className="">
            <img src={abhis} className="w-[240px] h[150px] mx-auto "></img>
          </div>

          <h2 className="text-2xl text-gray700 mt-8 mb-6 font-semibold text-center">
            Welcome
          </h2>

          <div className="flex flex-col text-gray-400 py-2 relative">
            {/* <label>Password</label> */}
            <span class="absolute  top-[22px] left-3 font-normal  text-center text-red-500 items-center justify-center ">
              <FaEnvelope className="text-xl" />
            </span>
            <div className="absolute flex text-color-red items-center" />
            <input
              className="rounded-lg bg-white px-11 py-3 focus:border-blue-50 focus:bg-gray-50 focus:outline-none "
              type="email"
              placeholder="Email Address"
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </div>
          <div className="w-full flex flex-col text-gray-400 py-2 relative">
            {/* <label>Password</label> */}
            <span className="absolute top-[18px] left-2 font-normal text-center text-green-500 items-center justify-center ">
              <img src={key} alt="key" className="w-7 h-7" />
              <span
                className="absolute text-gray-500 top-[6px] left-[290px]"
                onClick={togglePasswordVisibility}
              >
                {visible ? (
                  <FaEye className="" />
                ) : (
                  <FaEyeSlash className=" text-gray-500" />
                )}
              </span>
            </span>
            <div className="absolute flex text-color-red items-center" />
            <input
              className="rounded-lg bg-white px-11 py-3 focus:border-blue-50 focus:bg-gray-50 focus:outline-none"
              type={visible ? "text" : "password"} // Toggle input type based on visibility
              placeholder="Password"
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
          </div>
          <div className="w-[300px] flex flex-row justify-between text-gray-800 py-5 mx-auto">
            <div className="flex items-center text-[12px] font-medium text-gray-700">
              <input className="mr-2 w-4 h-4 text-red-600 " type="checkbox" />
              Remember Me
            </div>
            <Link to={""}>
              <p className="flex items-center text-[12px] font-semibold text-[rgb(21,164,157)] hover:bg-slate-200">
                Forgot Password
              </p>
            </Link>
          </div>
          <div className="w-full ">
            <button
              className="w-[300px] h-12 mx-4 bg-[rgb(21,164,157)] hover:bg-teal-500 shadow-lg text-xl text-white font-medium rounded"
              type="submit"
            >
              Sign In
            </button>
          </div>

          <span className="w-full text-gray-500 flex flex-row items-center justify-center mt-10 gap-6 ">
            <span className="text-[12px] cursor-pointer">Home</span>
            <span className="text-[12px] cursor-pointer">Support</span>
            <span className="text-[12px] cursor-pointer">Privacy</span>
          </span>
        </form>
      </div>
    </div>
  );
};

export default Login;
