import React, { useEffect, useState } from "react";

import Sidebar from "./SideBar";
import { Outlet } from "react-router-dom";
import BreadCrumbs from "../SubComponents/breadcrumbs";
import Navbar from "./Navbar";
import { FaFacebookSquare, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const Homepage = React.memo(() => {
  const [open, setOpen] = useState(true);
  const handleToggle = () => {
    setOpen(!open);
  };


  return (
    <div className="w-full flex bg-white">
      <Sidebar open={open} handleToggle={handleToggle} />
      <Navbar open={open} />
     
      <div
        className={`${
          open ? "absolute top-[50px] grow left-[262px] right-0 h-auto pr-6" : "absolute top-[50px] grow left-[6%] right-0 h-auto px-6"
        } duration-500  bg-white`}
      >
        <div className="py-1 capitalize">
        <BreadCrumbs/>
        </div>
      
        <Outlet />
        {/* <div className="w-full flex flex-row justify-between items-center py-[7px] px-8 ">
          <span className="text-[15px] text-primary cursor-pointer">V1.0.1 Copyright &copy; 2023 Abhis</span>
          <div className="flex flex-row items-center gap-3">
            <FaFacebookSquare className="text-2xl text-sky-500 cursor-pointer"/>
            <FiInstagram className="text-2xl text-rose-500 cursor-pointer"/>
            <FaWhatsapp className="text-2xl text-green-500 cursor-pointer"/>
            <FaLinkedin className="text-2xl text-blue-500 cursor-pointer"/>
          </div>
        </div> */}
      </div>
    </div>
  );
});

export default Homepage;
