import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

import { FaEdit } from "react-icons/fa";

import profile from "../Images/28.png";

import Calendar from "../cal/MainCalendar";
import { Input, Modal } from "antd";

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const SProfile = React.memo(({ open }) => {
  const [showlog, setShowlog] = useState(false);
  const [data, setData] = useState([]);



  const LMS_ROLE = JSON.parse(localStorage.getItem("lms_role"));



  const navigate = useNavigate();


  const getRole = () => {
    switch (LMS_ROLE) {
      case 1:
        return "Super Admin";

      case 2:
        return "Admin";
      case 3:
        return "Institute";

      case 4:
        return "Institute Admin";
      case 5:
        return "Branch";

      case 6:
        return "Branch Admin";

      case 7:
        return "Faculty";

      default:
        return "Student";
    }
  };

  const profileImagePaths = {
    // 1: "SuperAdminuploads",
    2: "AdminUploads",
    3: "InstituteUploads",
    4: "InstituteAdminUploads",
    5: "BranchUploads",
    6: "BranchAdminUploads",
    7: "Facultyuploads",
    8: "StudentUploads",
  };
  const profileImagePath = profileImagePaths[LMS_ROLE];
  const [name, setName] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [mobileNumber, setmobileNumber] = useState("");
  const [emailId, setemailId] = useState("");
  const [password, setpassword] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [pincode, setPincode] = useState("");
  const [area, setArea] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [country, setCountry] = useState("");
  const [id, setId] = useState("");
  const [code, setCode] = useState("");
  const [qualification, setQualification] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");

  
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState("");

const [newMobile, setNewMobile] = useState("");
const [newProfilePic, setNewProfilePic] = useState("");
const [newDOB, setNewDOB] = useState("");
const [newAddress, setNewAddress] = useState("");
const [newArea, setNewArea] = useState("")
const [newState, setNewState] = useState("")
const [newCountry, setNewCountry] = useState("")
const [ newDistrict , setNewDistrict] = useState("")
const [newPincode , setNewPincode] = useState("")



  const getName = () => {
    switch (LMS_ROLE) {
      case 1:
        return setName("Sathish Kumar");

      case 2:
        return axios
          .get(`${apiUrl}/admin/${localStorage.getItem("Unique_id")}`)

          .then((res) => setName(res.data.data[0].name));

      case 3:
        return axios
          .get(`${apiUrl}/institute/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setData(res.data.data);
            setName(res.data.data[0].instituteName);
            setmobileNumber(res.data.data[0].mobileNumber);
            setProfilePic(res.data.data[0].uploadInstituteLogo);
            setmobileNumber(res.data.data[0].mobileNumber);
            setemailId(res.data.data[0].emailId);
            setpassword(res.data.data[0].password);
            setAddressLine(res.data.data[0].addressLine);
            setArea(res.data.data[0].area);
            setPincode(res.data.data[0].pincode);
            setCountry(res.data.data[0].country);
            setState(res.data.data[0].state);
            setDistrict(res.data.data[0].district);
          });

      case 4:
        return axios
          .get(
            `${apiUrl}/collegeinstituteadmin/${localStorage.getItem(
              "Unique_id"
            )}`
          )
          .then((res) => {
            setData(res.data.data);
            setName(res.data.data[0].name);
            setProfilePic(res.data.data[0].uploadProfilePic);
            setmobileNumber(res.data.data[0].mobileNumber);
            setemailId(res.data.data[0].emailId);
            setpassword(res.data.data[0].password);
            setAddressLine(res.data.data[0].addressLine);
            setArea(res.data.data[0].area);
            setPincode(res.data.data[0].pincode);
            setCountry(res.data.data[0].country);
            setState(res.data.data[0].state);
            setDistrict(res.data.data[0].district);
          });
      case 5:
        return axios
          .get(`${apiUrl}/collegebranch/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setData(res.data.data);
            setName(res.data.data[0].branchName);
            setProfilePic(res.data.data[0].uploadBranchLogo);
            setmobileNumber(res.data.data[0].mobileNumber);
            setemailId(res.data.data[0].emailId);
            setpassword(res.data.data[0].password);
            setAddressLine(res.data.data[0].addressLine);
            setArea(res.data.data[0].area);
            setPincode(res.data.data[0].pincode);
            setCountry(res.data.data[0].country);
            setState(res.data.data[0].state);
            setDistrict(res.data.data[0].district);
          });

      case 6:
        return axios
          .get(
            `${apiUrl}/collegebranchadmin/${localStorage.getItem("Unique_id")}`
          )
          .then((res) => {
            setName(res.data.data[0].name);
            setProfilePic(res.data.data[0].uploadProfilePic);
            setmobileNumber(res.data.data[0].mobileNumber);
            setemailId(res.data.data[0].emailId);
            setpassword(res.data.data[0].password);
          });

      case 7:
        return axios
          .get(`${apiUrl}/collegefaculty/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setData(res.data.data);
            setName(res.data.data[0].facultyName);
            setProfilePic(res.data.data[0].uploadProfile);
            setmobileNumber(res.data.data[0].mobileNumber);
            setemailId(res.data.data[0].emailId);
            setCode(res.data.data[0].employeecode);
            setId(res.data.data[0].facultyId);
            setQualification(res.data.data[0].qualification);
            setGender(res.data.data[0].gender);
            setDob(res.data.data[0].dob);
            setBloodGroup(res.data.data[0].bloodGroup);
            setAddressLine(res.data.data[0].addressLine);
            setArea(res.data.data[0].area);
            setPincode(res.data.data[0].pincode);
            setCountry(res.data.data[0].country);
            setState(res.data.data[0].state);
            setDistrict(res.data.data[0].district);
          });

      default:
        return axios
          .get(`${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setData(res.data.data);
            setName(res.data.data[0].studentName);
            setmobileNumber(res.data.data[0].mobileNumber);

            setId(res.data.data[0].studentId);

            setGender(res.data.data[0].gender);
            setDob(res.data.data[0].dob);
            setBloodGroup(res.data.data[0].bloodGroup);
            setemailId(res.data.data[0].emailId);
            setpassword(res.data.data[0].password);
            setAddressLine(res.data.data[0].addressLine1);
            setArea(res.data.data[0].area);
            setPincode(res.data.data[0].pincode);
            setCountry(res.data.data[0].country);
            setState(res.data.data[0].state);
            setDistrict(res.data.data[0].district);
            setProfilePic(res.data.data[0].uploadStudentProfile);
            localStorage.setItem("batch_Id", res.data.data[0].batchId);
          });
    }
  };

  useEffect(() => {
    getName();
  }, []);

  const handleEditName = async () => {
    try {
      // Make an API call to update the name based on the user's role
      switch (LMS_ROLE) {
        case 1:
          // Handle Super Admin name update here
          break;
        case 2:
          await axios.put(
            `${apiUrl}/admin/${localStorage.getItem("Unique_id")}`,
            {
              name: newName,
            }
          );
          break;
        case 3:
          await axios.put(
            `${apiUrl}/institutedatatype/${localStorage.getItem("Unique_id")}`,
            {

              instituteName: newName,
              mobileNumber:newMobile,
              addressLine1:newAddress,
              area: newArea,
              district:newDistrict,
              state:newState,
              country:newCountry,
              pincode:newPincode
            }
          );
          break;
        case 4:
          await axios.put(
            `${apiUrl}/instituteadmindatatype/${localStorage.getItem("Unique_id")}`,
            {
              name: newName,
              mobileNumber:newMobile,
            }
          );
          break;
        case 5:
          await axios.put(
            `${apiUrl}/branchdatatype/${localStorage.getItem("Unique_id")}`,
            {
              branchName: newName,
              mobileNumber:newMobile,
              addressLine1:newAddress,
              area: newArea,
              district:newDistrict,
              state:newState,
              country:newCountry,
              pincode:newPincode
            }
          );
          break;
        case 6:
          await axios.put(
            `${apiUrl}/branchadmindatatype/${localStorage.getItem("Unique_id")}`,
            {
              name: newName,
              mobileNumber:newMobile,
             
            }
          );
          break;
        case 7:
          await axios.put(
            `${apiUrl}/facultydatatype/${localStorage.getItem("Unique_id")}`,
            {
              facultyName: newName,
              mobileNumber:newMobile,
              dob:newDOB,
              addressLine1:newAddress,
              area: newArea,
              district:newDistrict,
              state:newState,
              country:newCountry,
              pincode:newPincode
            }
          );
          break;

        default:
          await axios.put(
            `${apiUrl}/studentdatatype/${localStorage.getItem("Unique_id")}`,
            {
              studentName: newName,
              mobileNumber:newMobile,
              dob:newDOB,
              addressLine1:newAddress,
              area: newArea,
              district:newDistrict,
              state:newState,
              country:newCountry,
              pincode:newPincode
            }
          );
          break;
      }

      // Update the local state and notify the user
      setName(newName);
      toast.success("Name updated successfully");
      setIsEditingName(false);
      getName();
    } catch (error) {
      // Handle error
      console.error("Error updating name:", error);
      toast.error("Error updating name");
    }
  };

  return (
    <div className="flex flex-col border rounded-[10px] bg-white shadow-md w-full h-fit p-5 gap-2">
      <div className="flex flex-row justify-between w-full ">
        <h4 className="text-lg font-semibold">Profile</h4>

        <div className="flex justify-end text-primary">
          <FaEdit
            size={14}
            onClick={() => setShowlog(!showlog)}
            className="w-5 h-4 cursor-pointer"
          />
        </div>
      </div>
      <div className="w-full flex flex-col">
        <div className="w-full flex justify-center relative">
          <svg
            className="w-[130px] h-[130px]"
            viewBox="0 0 102 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.49476 71.7131C1.6611 65.3555 0.162274 58.484 0.0910427 51.5239C0.0198115 44.5637 1.37769 37.663 4.08064 31.2487L7.03806 32.495C4.50562 38.5046 3.23341 44.97 3.30015 51.491C3.36688 58.0121 4.77115 64.4501 7.42605 70.4066L4.49476 71.7131Z"
              fill="#D6F8F8"
            />
            <path
              d="M5.57612 28.0203C9.65794 19.9602 15.8127 13.1326 23.4076 8.23934C31.0026 3.34613 39.7636 0.563885 48.7902 0.17865L48.927 3.385C40.4699 3.74593 32.2616 6.35265 25.1458 10.9372C18.03 15.5217 12.2635 21.9186 8.43919 29.4702L5.57612 28.0203Z"
              fill="#69DED7"
            />
            <path
              d="M52.1819 0.14708C60.7086 0.352074 69.0468 2.69765 76.4299 6.96811C83.8129 11.2386 90.0037 17.2968 94.4329 24.5857L91.6903 26.2523C87.5405 19.4232 81.7403 13.7472 74.823 9.74614C67.9058 5.74509 60.0935 3.54749 52.1048 3.35542L52.1819 0.14708Z"
              fill="#12A189"
            />
            <path
              d="M96.1939 27.7293C99.8035 34.7449 101.732 42.5038 101.826 50.393C101.921 58.2821 100.18 66.0851 96.7393 73.1852L93.8512 71.7858C97.0744 65.1336 98.7061 57.8229 98.6175 50.4315C98.5288 43.04 96.7221 35.7705 93.3402 29.1976L96.1939 27.7293Z"
              fill="#105D50"
            />
          </svg>
          <img
            src={
              profilePic
                ? `${ImageUrl}/${profileImagePath}/${profilePic}`
                : profile
            }
            //onClick={() => setShowlog(!showlog)}
            className="w-24 h-24 rounded-full absolute top-4 right-auto"
            alt="Profile"
          />
        </div>
        <h4 className="text-lg font-medium text-center">{name}</h4>
      </div>
      <div className="w-full flex justify-center">
        <Calendar />
      </div>
      <Modal
        width={600}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        open={showlog}
        onCancel={() => setShowlog(!showlog)}
        footer={null}
      >
        <div className="">
          <div className="flex flex-col p-3 gap-2">
            <span className="flex justify-center font-semibold text-gray-800 text-lg">
              {" "}
              Account Information
            </span>
            <div className="flex justify-center">
              <img
                src={
                  profilePic
                    ? `${ImageUrl}/${profileImagePath}/${profilePic}`
                    : profile
                }
                alt="Profile"
                className="w-32 h-32 rounded-full bg-white border-4 border-primary"
              />
            </div>

            <div className="flex justify-center items-center mt-1 ">
            <p className="text-md ml-2 mr-2  font-semibold">name:</p>
            {isEditingName ? (
              <Input
                className="w-full  mt-2 border-2 border-primary"
                placeholder={name}
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
            ) : (
              <p className="text-xs font-semibold">{name}</p>
            )}
          </div>

          <div className="flex justify-end text-primary">
              <FaEdit size={14} onClick={() => setIsEditingName(true)} />
            </div>


          
            <span className="text-md capitalize mb-4 flex justify-center font-semibold">
              Role: {getRole()}
            </span>

            <div className="w-full border-b border-primary my-4"></div>

            <div className="flex flex-col ">
            <p className="text-md  mr-2  font-semibold">Mobile Number:</p>
            {isEditingName ? (
              <Input
                className="w-full border-2 border-primary"
                placeholder={mobileNumber}
                value={newMobile}
                onChange={(e) => setNewMobile(e.target.value)}
              />
            ) : (
              <p className="text-md font-semibold mt-4">
                Mobile Number:{" "}
                <span className="text-primary">{mobileNumber}</span>
              </p>
               )}
              <p className="text-md font-semibold mt-4">
                Email Id: <span className="text-primary">{emailId}</span>
              </p>

              <div className="text-md">
                {/* Render fields based on user's role */}
                {LMS_ROLE === 7 && (
                  // Fields for Faculty
                  <>
                    <p className="text-md font-semibold mt-4">
                      ID: <span className="text-primary">{id}</span>
                    </p>
                    <p className="text-md font-semibold mt-4">
                      Code: <span className="text-primary">{code}</span>
                    </p>
                    <p className="text-md font-semibold mt-4">
                      Qualification:{" "}
                      <span className="text-primary">{qualification}</span>
                    </p>
                    <p className="text-md font-semibold mt-4">
                      Gender: <span className="text-primary">{gender}</span>
                    </p>
                    <div className="text-md font-semibold mt-4">
                    <p className="text-md  mr-2  font-semibold">Date of Birth:</p>
                      {isEditingName ? (
              <Input
                className="w-full border-2 mt-2 border-primary "
                placeholder={dob}
                value={newDOB}
                onChange={(e) => setNewDOB(e.target.value)}
              />
            ) : (
                      <span className="text-primary">{dob}</span>
            )}
                      </div>
                    <p className="text-md font-semibold mt-4">
                      Blood Group:{" "}
                      <span className="text-primary">{bloodGroup}</span>
                    </p>
                  </>
                )}

                {LMS_ROLE !== 7 &&
                  LMS_ROLE !== 1 &&
                  LMS_ROLE !== 2 &&
                  LMS_ROLE !== 3 &&
                  LMS_ROLE !== 4 &&
                  LMS_ROLE !== 5 &&
                  LMS_ROLE !== 6 && (
                    // Fields for default case (not 1,2,3,4,5,6,7)
                    <>
                      <p className="text-md font-semibold mt-4">
                        ID: <span className="text-primary">{id}</span>
                      </p>
                      <p className="text-md font-semibold mt-4">
                        Gender: <span className="text-primary">{gender}</span>
                      </p>
                   
                      <p className="text-md font-semibold mt-4">
                        Date od birth:
                        
                      {isEditingName ? (
              <Input
                className="w-full "
                placeholder={dob}
                value={newDOB}
                onChange={(e) => setNewDOB(e.target.value)}
              />
            ) : (
                         <span className="text-primary">{dob}</span>
                         )}
                         </p>
                     
                    </>
                  )}
              </div>

              <div className="text-md mt-4 font-semibold">
             
                <p>Address:</p>
                {(LMS_ROLE !== 1 && LMS_ROLE !== 2) && ( // Check if the user is not Institute Admin and not Branch Admin
          <>

                {isEditingName ? (
              <Input
                className="w-full mt-4 border-2 border-primary"
                placeholder={addressLine ? addressLine : "Enter your address"}
                value={newAddress}
                onChange={(e) => setNewAddress(e.target.value)}
              />
            ) : (
                <p className=" ml-16 text-primary">{addressLine}</p>
            )}
             {isEditingName ? (
              <Input
                className="w-full mt-4 border-2 border-primary"
                placeholder={area ? area :"Enter your Area"}
                value={newArea}
                onChange={(e) => setNewArea(e.target.value)}
              />
            ) : (
                <p className="ml-16 text-primary">{area}</p>
            )}
             {isEditingName ? (
              <Input
                className="w-full mt-4 border-2 border-primary"
                placeholder={district ? district :"Enter your district"}
                value={newDistrict}
                onChange={(e) => setNewDistrict(e.target.value)}
              />
            ) : (
                <p className="ml-16 text-primary">{district}</p>
            )}
             {isEditingName ? (
              <Input
                className="w-full mt-4 border-2 border-primary"
                placeholder={state ? state: "Enter your district"}
                value={newState}
                onChange={(e) => setNewState(e.target.value)}
              />
            ) : (
                <p className="ml-16 text-primary">{state}</p>
            )}
             {isEditingName ? (
              <Input
                className="w-full mt-4 border-2 border-primary"
                placeholder={country ? country : "Enter your country"}
                value={newCountry}
                onChange={(e) => setNewCountry(e.target.value)}
              />
            ) : (
                <p className="ml-16 text-primary">{country}</p>
            )}
             {isEditingName ? (
              <Input
                className="w-full mt-4 border-2 border-primary"
                placeholder={pincode ? pincode : " Enter your pincode"}
                value={newPincode}
                onChange={(e) => setNewPincode(e.target.value)}
              />
            ) : (
                <p className="ml-16 text-primary">{pincode}</p>
            )}

</>
        )}
              </div>

              {isEditingName && (
            <div className="flex justify-center mt-2">
              <button type="primary" className="bg-primary px-4 py-2 rounded-md text-white hover:bg-secondary font-semibold " onClick={handleEditName}>
                Save
              </button>
            </div>
          )}



            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default SProfile;
