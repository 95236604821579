import React from "react";
import DataTable, { SortOrder } from "react-data-table-component";
import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { FaCheck, FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { TiCancel } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import upload from "../Images/upload.png";
import download from "../Images/download.png";
import axios from "axios";
import { Modal, Switch } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import FileUploadModal from "../Forms/fileuploadform";

const apiUrl = process.env.REACT_APP_API_URL;

const Admins = () => {
  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleToggle = async (id, value) => {
    const newStatus = value ? 0 : 1;
    try {
      await axios
        .put(`${apiUrl}/adminstatus/${id}`, {
          status: newStatus,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(
              `Admin ${newStatus ? "activated" : "deactivated"} successfully`
            );
            setStatus(!status);
          }
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (id, adminId) => {
    confirm({
      title: "Are you sure to delete this data?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios.delete(`${apiUrl}/admin/${id}`).then((res) => {
          //  console.log(res.data);
          if (res.status === 200) {
            setFilteredData((prevData) =>
              prevData.filter((item) => item._id !== id)
            );
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        });
        axios.delete(`${apiUrl}/adminlogin/${adminId}`);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,    
      // sortable: true, // Enable sorting for this column
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-3 w-[180px]">Name</h1>,
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: <h1 className="text-white pl-4 w-[200px]">Mail ID</h1>,
      selector: (row) => row.emailId,
      sortable: true,
      width: "200px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Mobile Number</h1>,
      selector: (row) => row.mobileNumber,
      sortable: true,
    },
    {
      name: <h1 className="text-white px-4 w-[140px]">Status</h1>,
      selector: (row) => (
        <div className="flex flex-row items-center ">
          <Switch
            checked={row.status === 1}
            onChange={() => handleToggle(row.adminId, row.status === 1)}
            className={
              row.status === 1
                ? "custom-switch-checked"
                : "custom-switch-unchecked"
            }
          />
        </div>
      ),
    },
    {
      name: <h1 className="text-white px-8 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" />
            <Link to={`/homepage/admins/${row.adminId}`}>
              <FaEdit className="w-5 h-5 text-blue-500 cursor-pointer" />
            </Link>
            <MdDelete
              className="w-6 h-6 text-red-500 cursor-pointer"
              onClick={() => showDeleteConfirm(row._id, row.adminId)}
            />
          </div>
        </>
      ),
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.name.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/admin`)
      .then((res) => {
        setItem(res.data.data);
        console.log(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [status]);

  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        "Admin ID": item.adminId,
        "Name": item.name,
        "Mail ID" :item.emailId,
        "Mobile Number": item.mobileNumber,
      }));
      return csvData;
    }
    return [];
  };

  return (
    <>
      <div className="flex flex-col  w-full ">
        <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
          <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
            <div className="flex flex-row items-center gap-4">
              <div className="relative">
                <input
                  placeholder="Search"
                  type="text"
                  required
                  className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                  onChange={(event) => setSearchTerm(event.target.value)}
                  value={searchTerm}
                />
                <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-secondary" />
              </div>

              <Link to="/homepage/admins/add admin">
                <button
                  className="py-3 px-4 text-[12px] font-sm rounded-md bg-primary text-white shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row 
          items-center gap-1"
                >
                  <FaPlus className="text-sm font-normal mb-0.5" />
                  Add Admin
                </button>
              </Link>
            </div>

            <div className="flex flex-row items-center gap-6">
              <div class="relative group flex items-center">
                <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                  {" "}
                  <img
                    alt="upload"
                    src={upload}
                    onClick={openModal}
                    className="w-5 h-5 mx-auto mt-2"
                  />
                </span>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Upload
                </div>
              </div>
              <div class="relative group flex items-center">
              <CSVLink
                  data={handleCsv()}
                  filename="Admins.csv"
                  class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
                >
                  {" "}
                  <img
                    alt="download"
                    src={download}
                    className="w-5 h-5 mx-auto mt-2"
                  />
               </CSVLink>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Download
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <DataTable
              fixedHeader={true}
              columns={columns}
              data={filteredData}
              selectableRows
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              fixedHeaderScrollHeight="358px"
              onChangePage={handlePageChange}
            />
          </div>
        </div>
        <FileUploadModal isOpen={isModalOpen} onClose={closeModal} menuType="admin" />

      </div>
    </>
  );
};

export default Admins;
