import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Homepage from "./components/Homepage";
import Home from "./Menus/Home";
import Admins from "./Menus/Admins";
import Institutes from "./Menus/Institutes";
import ELibrary from "./Menus/ELibrary";
import Reports from "./Menus/Reports";
import InstituteForm from "./Forms/InstituteForm";
import AdminForm from "./Forms/AdminForm";
import ELibraryForm from "./Forms/ELibraryForm";
import SupportType from "./Menus/SupportType";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoutes from "./SubComponents/PrivateRoutes";
import SuperAdminHomePage from "./Home/SuperAdminHomePage";


function App() {
  return (
    <div className="h-full w-full bg-white">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="homepage" element={<Homepage />}>
            {/* superadmin and admin menus */}
            <Route path="/homepage/home" element={<SuperAdminHomePage />} />
            <Route path="/homepage/institutes" element={<Institutes />} />
        
            <Route path="/homepage/admins" element={<Admins />} />
            <Route path="/homepage/reports" element={<Reports />} />
            <Route path="/homepage/e-library" element={<ELibrary />} />
            <Route
              path="/homepage/institutes/add institute"
              element={<InstituteForm />}
            />
            <Route
              path="/homepage/institutes/:id"
              element={<InstituteForm />}
            />
            <Route path="/homepage/admins/add admin" element={<AdminForm />} />

            <Route path="/homepage/admins/:id" element={<AdminForm />} />
            <Route
              path="/homepage/e-Library/add resource"
              element={<ELibraryForm />}
            />
            <Route path="/homepage/e-Library/:id" element={<ELibraryForm />} />
            <Route path="/homepage/support" element={<SupportType />} />

           
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
