import React, { useEffect, useRef, useState } from "react";
import { FaBell, FaComments, FaSignOutAlt, FaVideo } from "react-icons/fa";
import profile from "../Images/Profile.png";
import setting from "../Images/setting.png";
import { MdSettings } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const Navbar = React.memo(({ open }) => {
  const [showlog, setShowlog] = useState(false);

  const LMS_ROLE = JSON.parse(localStorage.getItem("lms_role"));

  const menuRef = useRef(null);
  //  const sideRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setShowlog(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    toast.success("Logged out successfully");
  };

  const getRole = () => {
    switch (LMS_ROLE) {
      case 1:
        return "Super Admin";

      case 2:
        return "Admin";
      case 3:
        return "Institute";

      case 4:
        return "Institute Admin";
      case 5:
        return "Branch";

      case 6:
        return "Branch Admin";

      case 7:
        return "Faculty";

      default:
        return "Student";
    }
  };

  const [name, setName] = useState("");
  const [profilePic, setProfilePic] = useState(null)

  const getName = () => {
    switch (LMS_ROLE) {
      case 1:
        return setName("Sathish Kumar");

      case 2:
        return axios
          .get(`${apiUrl}/admin/${localStorage.getItem("Unique_id")}`)
          .then((res) => setName(res.data.data[0].name));

      case 3:
        return axios
          .get(`${apiUrl}/institute/${localStorage.getItem("Unique_id")}`)
          .then((res) => setName(res.data.data[0].instituteName));

      case 4:
        return axios
          .get(`${apiUrl}/instituteadmin/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setName(res.data.data[0].name);
            setProfilePic(res.data.data[0].uploadProfilePic)
          });
      case 5:
        return axios
          .get(`${apiUrl}/branch/${localStorage.getItem("Unique_id")}`)
          .then((res) => setName(res.data.data[0].branchName));

      case 6:
        return axios
          .get(`${apiUrl}/branchadmin/${localStorage.getItem("Unique_id")}`)
          .then((res) => setName(res.data.data[0].name));

      case 7:
        return axios
          .get(`${apiUrl}/faculty/${localStorage.getItem("Unique_id")}`)
          .then((res) => setName(res.data.data[0].facultyName));

      default:
        return axios
          .get(`${apiUrl}/student/${localStorage.getItem("Unique_id")}`)
          .then((res) => setName(res.data.data[0].studentName));
    }
  };

  useEffect(() => {
    getName();
  }, []);

  return (
    <nav
      className={`${
        open
          ? "fixed top-0 right-0 left-[262px] flex grow"
          : "fixed top-0 right-0 left-[8%] flex grow"
      } duration-500 z-10 border-b-[1.3px] h-[50px] border-primary justify-end items-center bg-white px-5`}
    >
      <div className="flex items-center gap-4">
        {/* <div className="tooltip tooltip-bottom " data-tip="video"></div> */}
        <div class="relative group flex items-center">
          <button class="w-8 h-8 group-hover:bg-secondary rounded-full duration-500">
            {" "}
            <FaVideo className="text-xl cursor-pointer mx-auto" />
          </button>
          <div class="tooltip text-[11.5px] hidden group-hover:block bg-primary text-white text-sm px-3 py-0.5 rounded-md shadow-lg absolute top-[42px] left-4 transform -translate-x-1/2">
            Video
          </div>
        </div>
        <div class="relative group flex items-center">
          <button class="w-8 h-8 group-hover:bg-secondary rounded-full duration-500">
            {" "}
            <FaBell className="text-xl cursor-pointer mx-auto" />
          </button>
          <div class="tooltip text-[11.5px] hidden group-hover:block bg-primary text-white text-sm px-3 py-0.5 rounded-md shadow-lg absolute top-[42px] left-4 transform -translate-x-1/2">
            Notifications
          </div>
        </div>
        <div class="relative group flex items-center">
          <button class="w-8 h-8 group-hover:bg-secondary rounded-full duration-500">
            {" "}
            <FaComments className="text-xl cursor-pointer mx-auto" />
          </button>
          <div class="tooltip text-[11.5px] hidden group-hover:block bg-primary text-white text-sm px-3 py-0.5 rounded-md shadow-lg absolute top-[42px] left-4 transform -translate-x-1/2">
            Chat
          </div>
        </div>
        <div class="relative group flex items-center">
          <button
            class="w-8 h-8 group-hover:bg-secondary rounded-full duration-500"
            onClick={handleLogout}
          >
            {" "}
            <FaSignOutAlt className="text-xl cursor-pointer mx-auto" />
          </button>
          <div class="tooltip text-[11.5px] hidden group-hover:block bg-primary text-white text-sm px-3 py-0.5 rounded-md shadow-lg absolute top-[42px] left-4 transform -translate-x-1/2">
            Logout
          </div>
        </div>

        <div className="relative" ref={menuRef}>
          <img
            src={profile}
            alt="Profile"
            className="w-10 h-10 rounded-full cursor-pointer"
            onClick={() => setShowlog(!showlog)}
          />

          {showlog && (
            <>
              <div className="fixed top-16 right-4 h-fit w-[150px] bg-white flex flex-col rounded-lg shadow border border-gray-50">
                <div className="flex flex-col p-3 gap-2">
                  <span className="font-semibold  text-gray-800">Account</span>
                  <span className="text-nav-ash font-md capitalize">
                    {name}
                  </span>
                  <span className="text-nav-ash font-md capitalize">
                    Role : {getRole()}
                  </span>
                </div>
                <div className="w-full h-px bg-gray-300"></div>
                <div className="w-full flex flex-col p-2 gap-2">
                  <div className="flex flex-col  justify-between">
                    {/* <Link to={`/homepage/setting/${getData}`}> */}{" "}
                    <span
                      className="flex flex-row items-center gap-2 cursor-pointer text-gray-600 pl-1 pr-2 py-1 font-md hover:rounded hover:duration-300  hover:bg-secondary hover:text-white"
                      onClick={() => setShowlog(!showlog)}
                    >
                      <MdSettings className="text-[22px]" />
                      Settings
                    </span>
                    {/* </Link> */}
                  </div>
                  <div className="flex flex-col  justify-between">
                    <span
                      className="flex flex-row items-center gap-2 cursor-pointer px-2 py-1
                      hover:bg-secondary hover:text-white hover:rounded hover:duration-300 text-gray-600 font-md"
                      onClick={handleLogout}
                    >
                      <FaSignOutAlt className="text-[18px]" />
                      Log out
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
});

export default Navbar;
