import React, { Fragment } from "react";
import {
  FaAccessibleIcon,
  FaAppStore,
  FaBars,
  FaBook,
  FaBookReader,
  FaBuilding,
  FaBullhorn,
  FaCalendarWeek,
  FaCheckDouble,
  FaChevronLeft,
  FaChevronRight,
  FaClipboardList,
  FaFile,
  FaFolderOpen,
  FaGraduationCap,
  FaHome,
  FaIdCard,
  FaPhone,
  FaPhoneAlt,
  FaPlusSquare,
  FaRegWindowMaximize,
  FaUser,
  FaUserGraduate,
  FaUserPlus,
  FaUsers,
  FaVrCardboard,
} from "react-icons/fa";
import Result from "../Images/result.png";
import rightMenu from "../Images/double-arrow.png";
import { NavLink, useLocation } from "react-router-dom";
import abhis from "../Images/abhis.png";
import leftmenu from "../Images/arrow.png";
import { MdCall, MdEditDocument } from "react-icons/md";
import { FiFileText } from "react-icons/fi";

const SideBar = React.memo(({ handleToggle, open }) => {
  const LMS_ROLE = localStorage.getItem("lms_role");

  const REPORT_PERMISSION_ADD = localStorage.getItem("reportpermission_add");

  const SuperadminMenus = [
    { title: "Home", icon: <FaHome />, path: "/homepage/home" },
    { title: "Institutes", icon: <FaBuilding />, path: "/homepage/institutes" },
    { title: "Admins", icon: <FaUserPlus />, path: "/homepage/admins" },
    { title: "Reports", icon: <MdEditDocument />, path: "/homepage/reports" },
    { title: "E-Library", icon: <FiFileText />, path: "/homepage/e-library" },
    { title: "Support", icon: <MdCall />, path: "/homepage/support" },
  ];

  const adminMenus = [
    { title: "Home", icon: <FaHome />, path: "/homepage/home" },
    { title: "Institutes", icon: <FaBuilding />, path: "/homepage/institutes" },
    { title: "Reports", icon: <MdEditDocument />, path: "/homepage/reports" },
    { title: "E-Library", icon: <FiFileText />, path: "/homepage/e-library" },
    { title: "Support", icon: <MdCall />, path: "/homepage/support" },
  ];

  const adminMenusWithoutReport = [
    { title: "Home", icon: <FaHome />, path: "/homepage/home" },
    { title: "Institutes", icon: <FaBuilding />, path: "/homepage/institutes" },
    { title: "E-Library", icon: <FiFileText />, path: "/homepage/e-library" },
    { title: "Support", icon: <MdCall />, path: "/homepage/support" },
  ];

  return (
    <div
      className={`${
        open ? "w-[238px]" : "w-[6%]"
      } bg-primary left-0 fixed duration-500 h-screen overflow-y-scroll overflow-x-hidden`}
    >
      <div className="w-full border-b px-[18px] py-3 h-[100px]">
        {open ? (
          <>
            <div className="w-full flex flex-row items-center gap-6">
              <div className="flex flex-row items-center">
                <img
                  src={abhis}
                  className={` w-[45px] cursor-pointer h-fit `}
                />
                <h1 className="text-[45px] text-white font-bold mt-[6px]">
                  bhis
                </h1>
              </div>

              <img
                alt="rightmenu"
                src={rightMenu}
                className={`cursor-pointer w-7 h-7 mt-3 text-gray-900
             `}
                onClick={handleToggle}
              />
            </div>
          </>
        ) : (
          <>
            <div className="w-full flex justify-center items-center h-full ">
              <FaBars
                className={` cursor-pointer w-7 h-7 text-white
             `}
                onClick={handleToggle}
              />
              {/* <FaBars
                  className={` cursor-pointer w-7 h-7 text-white
             `}
                  onClick={handleToggle}
                /> */}
            </div>
          </>
        )}
      </div>
      {LMS_ROLE === "1" && (
        <div className={`w-full flex flex-col gap-1 py-[10px]`}>
          {SuperadminMenus.map((menu, id) => (
            <Fragment key={id}>
              <NavLink
                to={menu.path}
                isActive={(match, location) => {
                  // Check if the current location matches the menu's path or any of its subpaths
                  return (
                    match || location.pathname.startsWith(menu.path + "/") // Assuming child routes have paths like /homepage/institutes/child
                  );
                }}
                style={({ isActive }) => {
                  return isActive
                    ? {
                        background: `${
                          open
                            ? "linear-gradient(to right, #ffffff 3%, #FF9800 3%)"
                            : "linear-gradient(to right, #ffffff 6%, #FF9800 6%)"
                        }`,

                        // borderLeft: "6px solid white",
                      }
                    : {
                        // color: "white",
                        // borderLeft: "6px solid #105D50",
                      };
                }}
              >
                <div className="w-full flex flex-row pl-[18px] pr-[12px] py-[13px] gap-4  hover:bg-custom-gradient">
                  <span
                    className={`text-[24px] text-white ${!open && "mx-auto"}`}
                  >
                    {menu.icon}
                  </span>
                  <h1
                    className={`${
                      open ? "text-[15px]" : "hidden"
                    } duration-500 text-white font-medium`}
                  >
                    {menu.title}
                  </h1>
                </div>
              </NavLink>
            </Fragment>
          ))}
        </div>
      )}

      {LMS_ROLE === "2" && (
        <>
          {REPORT_PERMISSION_ADD === "false" ? (
            <div className={`w-full flex flex-col gap-1 py-[10px]`}>
              {adminMenusWithoutReport.map((menu, id) => (
                <Fragment key={id}>
                  <NavLink
                    to={menu.path}
                    isActive={(match, location) => {
                      // Check if the current location matches the menu's path or any of its subpaths
                      return (
                        match || location.pathname.startsWith(menu.path + "/") // Assuming child routes have paths like /homepage/institutes/child
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            background: `${
                              open
                                ? "linear-gradient(to right, #ffffff 3%, #FF9800 3%)"
                                : "linear-gradient(to right, #ffffff 6%, #FF9800 6%)"
                            }`,

                            // borderLeft: "6px solid white",
                          }
                        : {
                            // color: "white",
                            // borderLeft: "6px solid #105D50",
                          };
                    }}
                  >
                    <div className="w-full flex flex-row pl-[18px] pr-[12px] py-[13px] gap-4  hover:bg-custom-gradient">
                      <span
                        className={`text-[24px] text-white ${
                          !open && "mx-auto"
                        }`}
                      >
                        {menu.icon}
                      </span>
                      <h1
                        className={`${
                          open ? "text-[15px]" : "hidden"
                        } duration-500 text-white font-medium`}
                      >
                        {menu.title}
                      </h1>
                    </div>
                  </NavLink>
                </Fragment>
              ))}
            </div>
          ) : (
            <div className={`w-full flex flex-col gap-1 py-[10px]`}>
              {adminMenus.map((menu, id) => (
                <Fragment key={id}>
                  <NavLink
                    to={menu.path}
                    isActive={(match, location) => {
                      // Check if the current location matches the menu's path or any of its subpaths
                      return (
                        match || location.pathname.startsWith(menu.path + "/") // Assuming child routes have paths like /homepage/institutes/child
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            background: `${
                              open
                                ? "linear-gradient(to right, #ffffff 3%, #FF9800 3%)"
                                : "linear-gradient(to right, #ffffff 6%, #FF9800 6%)"
                            }`,

                            // borderLeft: "6px solid white",
                          }
                        : {
                            // color: "white",
                            // borderLeft: "6px solid #105D50",
                          };
                    }}
                  >
                    <div className="w-full flex flex-row pl-[18px] pr-[12px] py-[13px] gap-4  hover:bg-custom-gradient">
                      <span
                        className={`text-[24px] text-white ${
                          !open && "mx-auto"
                        }`}
                      >
                        {menu.icon}
                      </span>
                      <h1
                        className={`${
                          open ? "text-[15px]" : "hidden"
                        } duration-500 text-white font-medium`}
                      >
                        {menu.title}
                      </h1>
                    </div>
                  </NavLink>
                </Fragment>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default SideBar;
